/*Based on size 375px width*/
header.is_for_mobile{
  display: none !important;
}
@media (max-width: 800px){


  body {
    --grid_padding: 15px;
    --container_width: 100%;

    font-size: 16px;
  }


  /*bootstrap start*/
  .btn {
    border: solid 5px #f2ecd1;
    border-radius: 5px;
    font-size: 18px;
    padding: 12px 20px;
    text-shadow: 1px 1px 4px #000000;
    box-shadow: 0 10px 10px 1px rgba(0,0,0,0.3);
  }
  .form-control {
    border-radius: 5px;
  }
  .form-control {
    padding: 10px 20px;
    font-size: 22px;
  }
  .input-group{
    display: block;
  }
  .input-group>*{
    width: 100% !important;
    margin: 0px !important;
  }
  .input-group>*:first-child{
    -webkit-border-radius: 5px 5px 0 0 !important;
    border-radius: 5px 5px 0 0 !important;
  }
  .input-group>*:last-child{
    -webkit-border-radius: 0 0 5px 5px !important;
    border-radius: 0 0 5px 5px !important;
  }

  .input-group>*:last-child,
  .input-group>*{
    border-radius: 5px !important;
  }
  .newsletter_content .input-group{
    margin-bottom: 1.5vh;
  }
  /*bootstrap end*/





  header{
    top: 200px;
  }
  header.is_for_mobile{
    display: block !important;
    top: 0px;
  }
  .header_left_menu_set{
    width: 100%;
    justify-content: space-between;
  }
  .header_main_logo img{
    height: 40px;
  }
  header.is_for_mobile .header_main_logo{
    position: static;

    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  .btn.hamburg_button.clicked,
  .btn.hamburg_button{
    position: relative;
    border: none !important;
    background: none !important;
    -webkit-box-shadow: 0 0 0 0 rgba(0,0,0,0) !important;
    box-shadow: 0 0 0 0 rgba(0,0,0,0) !important;
  }
  body{
    --hamburg_side_space: 5px;
    --hamburg_tb_space: 5px;
    --hamburg_line_height: 5px;
    --hamburg_width: 50px;
    --hamburg_height: 40px;
  }
  .hamburg_button{
    width: var(--hamburg_width);
    height: var(--hamburg_height);
    border: none;
  }
  .hamburg_button>span{
    display: block;
    position: absolute;
    left: var(--hamburg_side_space);
    height: var(--hamburg_line_height);
    width: calc(100% - 2 * var(--hamburg_side_space));
    background-color: #f8f5ee;

    -webkit-border-radius: calc(var(--hamburg_line_height)/2);
    border-radius: calc(var(--hamburg_line_height)/2);

    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .hamburg_button>span:nth-child(1){
    top: var(--hamburg_tb_space);
    transform-origin: left center;
  }
  .hamburg_button>span:nth-child(2),
  .hamburg_button>span:nth-child(3){
    top: calc(50% - var(--hamburg_line_height)/2);
  }
  .hamburg_button>span:nth-child(4){
    bottom: var(--hamburg_tb_space);
    left: auto;
    right: var(--hamburg_side_space);
    transform-origin: right center;
  }
  .hamburg_button.clicked>span:nth-child(1){
    -moz-transform: scaleX(0);
    -webkit-transform: scaleX(0);
    -o-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
  }
  .hamburg_button>span:nth-child(2),
  .hamburg_button>span:nth-child(3){
    top: calc(50% - var(--hamburg_line_height)/2);
  }
  .hamburg_button.clicked>span:nth-child(2){
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .hamburg_button.clicked>span:nth-child(3){
    -moz-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .hamburg_button.clicked>span:nth-child(4){
    -moz-transform: scaleX(0);
    -webkit-transform: scaleX(0);
    -o-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
  }
  .hamburg_button.clicked{
    background-color: black !important;
  }
  header:not(.is_for_mobile) .header_main_logo{
    display: none;
  }
  header{
    height: auto;
    padding: 10px 0px;
    border-top: none;
  }
  header .btn_header{
    min-width: 250px;
  }
  header .header_left_menu_set{
    margin-bottom: 5vw;
  }
  header:not(.is_for_mobile){
    top: 63px;
    height: calc(100vh - 63px);
    border:none !important;
    overflow: auto;

    padding-top: 30px;
    padding-bottom: 30px;

    display: block;

    background-color: #3f2130 !important;
  }
  header:not(.is_for_mobile) .col-lg-12{
    display: block;
  }
  header:not(.is_for_mobile) .col-lg-12 ul{
    display: block;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }
  .header_left_menu>li{
    margin-right: 0px;
    text-align: center;
  }
  .header_menu_user>li{
    margin-right: 0px;
    text-align: center;
  }
  .btn_icon_header{
    padding: 15px 0px;
  }
  header.make_header_for_active_hamburg{
    background-color: #3f2130;
    border-bottom-style: solid;
    border-bottom-color: #f8f5ee;
    border-bottom-color: #735D68;
  }
  header.make_header_for_active_hamburg{
  }
  header:not(.is_for_mobile){
    display: none;
  }
  body.menu_is_visible header:not(.is_for_mobile){
    display: block;
  }
  .header_menu_user li{
    margin-bottom: 15px;
  }
  body.menu_is_visible{
    overflow: hidden;
  }
  header{
    border-width: 3px;
  }
  .btn_icon_header,
  .header_left_menu a{
    font-size: 18px;
    padding: 5px 0px;
  }
  .btn_icon_header img{
    vertical-align: -3px;
    height: 18px;
    margin-right: 7px;
  }



  .big_header_with_banner .container{
    max-width: 100%;
  }
  .big_header_with_banner .btn{
    min-width: 0px;
  }
  .big_header_with_banner .content{
    max-width: 300px;
    max-width: 235px;
    max-width: 100%;
  }
  .big_header_with_banner{
    min-height: 330px;
    padding: 140px 0px;
    height: auto;
  }
  .big_header_with_banner .content>h1{
    font-size: 50px;
    margin-bottom: 20px;
    text-shadow: 0px 3px 0px #f06230;
  }
  .big_header_with_banner .content>h1>*{
  }
  .big_header_with_banner .content>h1>strong{
    margin-bottom: 3px !important;
    text-shadow: 0px 3px 0px #fab132;
    
  }
  .big_header_with_banner .content>h1>i{
    margin-bottom: 7px;
    text-shadow: 0px 3px 0px transparent;
  }


  .liberty_banner_content>div{
    margin-bottom: 20px;
  }
  .liberty_banner .liberty_banner_content h1, .liberty_banner .liberty_banner_content h2, .liberty_banner .liberty_banner_content h3 {
    font-size: 35px;
    letter-spacing: -3px;
    letter-spacing: 0px;
    text-shadow: 0px 3px 0px #bdb785;
    margin-bottom: 20px;
  }
  .liberty_banner .liberty_banner_content {
    border-radius: 30px;
    padding: var(--grid_padding);
    padding-top: 35px;
    padding-bottom: 30px;
  }
  .liberty_banner{
    background-size: 100% auto;
    padding-top: 55px;
    padding-bottom: 70px;
  }


  .content_photo{
    padding: 50px 0px;
  }
  .content_photo:before {
    right: 0px;
    top: 0px;
    border: solid 5px #fcf5e9;
    border-radius: 0px 0 0 0px;
    border-right: none;
    border-left: none;
    width: 100%;
  }
  .content_photo.padding-bottom-for-x3-boxes{
    padding: 50px 0px;
  }
  .content_photo .inner_content h2, .content_photo .inner_content h3 {
    font-size: 35px;
    letter-spacing: 0px;
    margin-bottom: 20px;
    text-shadow: 0px 4px 0px #ac4a39;
  }
  .content_photo:after{
    background-size: 100% auto;
    height: 150px;
    background-image: url('../Images/stars-set-for-mobile.png');
  }


  .x3_yellow_boxes.margin-top-go-up{
    margin-top: 50px;
  }
  .x3_yellow_boxes{
    margin-bottom: 50px;
    padding-bottom: 0px;

    background-size: 100% auto;
    background-position: center;
    background-repeat: repeat-y;
    background-image: url('../Images/stars-set-for-mobile.png');
  }
  .list_yellow_boxes{
    margin-bottom: 35px;
    display: block;
  }
  .list_yellow_boxes li>span.logo{
    margin-bottom: 30px;
  }
  .list_yellow_boxes li>span.logo .logo_background, .list_yellow_boxes li>span.logo:before {
    width: 120px;
    height: 120px;
    left: calc(50% - 120px/2);
    top: calc(50% - 120px/2);
  }
  .list_yellow_boxes li>span.logo:after {
    width: 140px;
    height: 140px;
    left: calc(50% - 140px/2);
    top: calc(50% - 140px/2);
  }
  .list_yellow_boxes li>span.logo>img {
    width: 80px;
    height: 80px;
  }
  .list_yellow_boxes li {
    font-size: 18px;
    border: solid 5px #fff9ef;
    padding: 20px var(--grid_padding);
    padding-top: 30px;
    padding-bottom: 25px;
    border-radius: 40px;
    box-shadow: 0 5px 0 1px #d2cbc2;
    margin-bottom: 40px;
  }
  .list_yellow_boxes h4 {
    margin-bottom: 15px;
    font-size: 22px;
    text-shadow: 0px 3px 0px #ac4a39;
  }


  .content_centered{
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .content_centered:before {
    left: 0px;
    top: 0px;
    width: 100%;
    border: solid 5px #fcf5e9;
    border-radius: 0 0px 0px 0;
    border-left: none;
    border-right: none;
  }
  .content_centered .content h5 {
    font-size: 16px;
    letter-spacing: 6px;
    margin-bottom: 17px;
  }
  .content_centered .content h2, .content_centered .content h3 {
    font-size: 40px;
    letter-spacing: 0px;
    text-shadow: 0px 3px 0px #ac4a39;
  }
  .content_centered .content{
    margin-bottom: 40px;
  }
  .list_box_posts li{
    padding-top: 300px;
  }
  .list_box_posts li span.date {
    font-size: 14px;
    margin-bottom: 10px;
  }


  .logos_box{
    padding-top: 50px;
  }
  .logox_box_content {
    padding: 35px var(--grid_padding);
    padding-bottom: 50px;
    border-radius: 40px;
  }
  .logos_box .content h2, .logos_box .content h3 {
    margin-bottom: 20px;
    font-size: 25px;
    letter-spacing: 0px;
    text-shadow: 2px 2px 0px #fcddaf;
  }
  .logos_box .content p{
    font-size: 18px;
  }
  .logox_box_content .the_logos img {
    height: 40px;
    max-width: 150px;
    margin: 20px 10px !important;
  }

  .newsletter .banner_holder img{
    width: 100%;
    height: 170px;
    height: 100%;
    left: -110px;
    left: 0px;

    object-position: left bottom;
    -o-object-position: left bottom;
  }
  .newsletter{
    background-size: 100% auto;
    background-image: url('../Images/stars-set-for-mobile.png');
    margin-bottom: 50px;

    padding-top: 60px;
    padding-bottom: 80px;
  }
  .newsletter_content{
    max-width: 270px;
  }
  .newsletter .html_content h5 {
    font-size: 18px;
    margin-bottom: 15px;
    letter-spacing: 6px;
  }
  .newsletter .html_content h2, .newsletter .html_content h3 {
    font-size: 40px;
    letter-spacing: 0px;
    text-shadow: 0px 3px 0px #ac4a39;
    margin-bottom: 15px;

  }
  .newsletter .html_content p{
    font-size: 18px;
    margin-bottom: 15px;
  }
  .newsletter .html_content{
    margin-bottom: 20px;
  }
  .newsletter_content{
    max-width: 100%;
  }
  .newsletter .html_content h2, .newsletter .html_content h3{
    font-size: 33px;
  }
  .newsletter .container{
    max-width: 100%;
  }


  footer{
    padding: 50px 0px;
  }
  .footer_main_link img{
    width: 100%;
  }
  .footer_main_link{
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    width: 200px;
  }
  footer .col-lg-12{
    display: block;
    text-align: center;
  }
  .footer_social_icons{
    justify-content: center;
  }
  .footer_social_icons a>img{
    width: 40px;
    height: 40px;
  }
  .footer_social_icons>li{
    margin: 0px 5px;
  }


  .big_header_with_content .the_planet{
    top: 70px;
    height: 100px;
  }
  .big_header_with_content{
    min-height: 0px;
    padding-top: 150px;
    padding-bottom: 120px;
    height: auto;
  }
  .big_header_with_content .content h1, .big_header_with_content .content h2 {
    font-size: 40px;
    text-shadow: 3px 3px 0px #784D2E;
    margin-bottom: 20px;
  }
  .big_header_with_content .content{
    max-width: 100%;
  }
  .big_header_with_content .rockets_holder .rockets{
    height: 100%;
    width: 100%;
    background-position: left bottom;
    bottom: auto;
    top: 150px;
    top: 0px;
    background-image: url('../Images/rockets-2-mobile.png');
    background-size: auto 420px;
  }
  .big_header_with_content.the_people_look .rockets_holder .rockets{
    background-image: url('../Images/the-people-rockets-for-mobile.png');
  }
  .big_header_with_content .rockets_holder:after{
    display: none;
  }
  .big_header_with_content .stars{
    background-image: url('../Images/stars-set-for-mobile.png');
  }
  .big_header_with_content .rockets_holder{
    bottom: auto;
    top: 0px;
  }
  .big_header_with_content.the_green_planet .rockets_holder{
    background-image: url('../Images/the-green-planet-for-mobile.png');
  }

    
  .big_header_with_content.is_for_post .col-lg-12{
    justify-content: space-between;
    align-items: flex-start;
    display: block;
  }
  .big_header_with_content.is_for_post .col-lg-12>*{
    width: 100%;
  }
  .big_header_with_content.is_for_post .col-lg-12>img{

    max-height: 10000px;

    width: 80%;

    margin-left: auto;
    margin-right: auto;

    margin-bottom: 8vw;

    border: solid 2vw #fff9ef;
    border-radius: 5vw;
    box-shadow: 1vw 1vw 0 1px #d2cbc2;
  }
  .big_header_with_content.is_for_post .col-lg-12>img.post_default_image{
    width: 80%;
  }

  .big_header_with_content .content p{
    font-size: 18px;
  }




  .content_tv_circle.title_smaller h2{
    font-size: 35px;
  }
  .content_tv_circle h2 {
    font-size: 40px;
    margin-bottom: 20px;
    text-shadow: 0px 3px 0px #78482b;
    letter-spacing: 0px;
  }
  .content_tv_circle{
    background-image: url('../Images/stars-set-for-mobile.png');
    background-repeat: repeat;
    background-size: 100% auto;
  }
  .content_tv_circle .circle_content.just_tv {
    padding: 25px 0px;
    max-width: 100%;
  }

  .content_tv_circle .the_tv:before {
    left: 50%;
    width: 375px;
    top: 50%;
    height: 320px;

    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
  .content_tv_circle .circle_content{
    background-size: 100% calc(100% + 100px);
  }
  .content_tv_circle .the_tv{
    width: 250px;
  }

  .content_photo .content_top {
    margin-bottom: 35px;
  }
  .content_photo .content_top h2, .content_photo .content_top h3 {
    margin-bottom: 20px;
    font-size: 32px;
    text-shadow: 0px 3px 0px #ac4a39;
  }
  .icons_content li{
    display: block;
    margin-bottom: 50px;
  }
  .icons_content li>*{
    width: 100%;
    text-align: center;
  }
  .icons_content li .image {
    width: 130px;
    height: 130px;
    margin-bottom: 5px;
    margin-left: auto;
    margin-right: auto;
  }
  .icons_content li p {
    margin-bottom: 20px;
    font-size: 18px;
  }
  .icons_content li .btn {
    min-width: 250px;
  }
  .icons_content li .content{
    width: 100%;
  }
  .icons_content li .image img {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    box-shadow: 0 5px 0 1px transparent;
  }

  .content_centered_2 {
    padding-top: 70px;
    padding-bottom: 50px;
  }
  .content_centered_2 .left_circle {
    height: 100px;
    top: 100px;
  }
  .content_centered_2 .right_circle{
    height: 140px;
    top: 50px;
  }
  .content_centered_2 h1, .content_centered_2 h2, .content_centered_2 h3 {
    font-size: 40px;
    margin-bottom: 30px;
    text-shadow: 0px 3px 0px #78482b;
    letter-spacing: 0px;
  }
  .content_centered_2 p {
    font-size: 18px;
    margin-bottom: 25px;
  }
  .buttons_holder>*{
    min-width: 250px;
    margin: 0 auto;
    margin-bottom: 10px;
  }

  .big_header_with_content .btn{
    min-width: 250px;
  }
  .big_header_with_content.add_margin{
    margin-bottom: 0px;
  }

  .content_box_item {
    border: solid 5px #fff9ef;
    border-radius: 40px;
    margin-bottom: 70px;
    padding: 70px var(--grid_padding);
    min-height: 0px;
  }
  .content_box_item h2, .content_box_item h3 {
    font-size: 32px;
    margin-bottom: 35px;
    letter-spacing: 0px;
    text-shadow: 0px 3px 0px #ac4a39;
  }
  .content_box_item p{
    font-size: 22px;
  }
  .content_box_item>div{
    max-width: 100%;
  }
  .content_photo.planets-and-stars:before{
    background-image: url('../Images/stars-set-for-mobile.png');
  }








  .login_form_inputs {
    max-width: 600px;
    max-width: 100%;
    width: 80vw;
    padding: var(--grid_padding);
    padding-bottom: 25px;
    border-radius: 20px;
  }
  .login_form_ufo{
    max-width: 220px;
    margin-bottom: 30px;
  }
  .login_form_inputs .login_logo{
    width: 150px;
    margin-bottom: 20px;
  }
  .login_form_control_holder .form-control {
    border-radius: 5px;
    font-size: 16px;
    padding: 15px 18px;
  }
  .login_form_control_holder.have_icon .form-control{
    padding-right: calc(30px + 10px);
  }
  .login_form_control_holder.have_icon>img {
    height: 20px;
    left: calc(100% - 20px - 10px);
    top: calc(50% - 20px/2);
  }
  .login_form_control_holder{
    margin-bottom: 20px;
  }
  .login_big_button_holder>.btn {
    min-width: 150px;
    -webkit-box-shadow: -5px 5px 4px 1px rgba(0,0,0,0.2);
    box-shadow: -5px 5px 4px 1px rgba(0,0,0,0.2);
  }
  .login_big_button_holder {
    padding-top: 10px;
    margin-bottom: 20px;
  }
  .login_forgot_link{
    margin-bottom: 40px;
  }


  .dashboard_sidebar_menu{
    width: 270px;
  }
  .dashboard_sidebar_menu .d_logo {
    width: 113px;
    margin: 0 auto;
    padding-top: 25px;
    padding-bottom: 30px;
  }
  .dashboard_sidebar_menu a {
    font-size: 18px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .dashboard_sidebar_menu>ul>li>a {
    padding-left: 15px;
    padding-right: 15px;
  }
  .dashboard_sidebar_menu li a>img {
    width: 24px;
    height: 22px;
    margin-right: 15px;
    vertical-align: -3px;
  }
  .dashboard_sidebar_menu li a.account_link>img {
    width: 38px;
    height: 38px;
    margin-right: 5px;
  }
  .dashboard_sidebar_menu .d_logo{
    padding-right: 0px;
  }
  .dashboard_sidebar_menu .d_logo img {
    height: 27px;
  }
  .dashboard_sidebar_menu>ul>li>.submenu a {
    padding: 10px 0px;
    padding-left: 37px;
    padding-left: 52px;
  }
  .dashboard_sidebar_menu>ul {
    height: calc(100vh - 135px - 80px);
  }

  .dashboard_sidebar_menu ul.logged_user {
    left: 0px;
    bottom: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .dashboard_sidebar_menu ul.logged_user li {
    margin-bottom: 15px;
  }
  .dashboard_sidebar_menu>ul {
    height: calc(100vh - 135px - 110px);
  }
  .dashboard_sidebar_menu ul.logged_user li{
    margin-bottom: 5px;
  }
  .dashboard_sidebar_menu>ul>li.opened {
    margin-bottom: 15px;
  }
  .dashboard_sidebar_menu>ul>li.opened>.submenu {
    padding-bottom: 10px;
    padding-bottom: 20px;
  }
  .dashboard_sidebar_menu{
    left: -270px;
  }
  body.d_sidebar_is_visible .dashboard_sidebar_menu{
    left: 0px;
  }
  .dashboard_sidebar_menu{
    z-index: 10000;

    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }



  .dashboard_counts_grid .col-lg-12{
    display: block;
  }
  .dashboard_panel_count{
    margin-bottom: 20px;
  }
  .dashboard_content_area {
    padding: 95px 80px;
    padding-right: 120px;
    padding: 30px 20px;
    overflow: auto;
    height: 100vh;
    height: auto !important;
    width: calc(100% - 270px);
    width: 100% !important;
    margin-left: 270px;
    margin-left: 0px;
  }
  .dashboard_content_area{
    padding-left: 0px;
    padding-right: 0px;
  }

  .dashboard_panel_count h4{
    font-size: 20px;
  }
  .dashboard_panel_count .count{
    padding: 40px;
    font-size: 50px;
  }
  .dashboard_panel_count .count small{
    font-size: 16px;
  }
  .dashboard_panels_x2_areas .area_1{
    min-height: 95px;
  }
  .dashboard_panels_x2_areas>div {
    margin-bottom: 20px;
    border-radius: 15px;
  }
  .dashboard_panels_x2_areas .area_2{
    min-height: 170px;
  }
  .dashboard_content_area .container {
    margin-bottom: 20px;
  }

  .dashboard_content_panel {
    padding: 20px 30px;
    padding: 20px 20px;
    padding-bottom: 30px;
    min-height: 0px !important;
  }
  .dashboard_panel_count {
    border-radius: 20px;
    padding: 25px 35px;
  }
  .dashboard_content_panel {
    border-radius: 15px;
  }
  .dashboard{
    height: auto;
    padding-top: calc(63px + 10px);
  }
  .dashboard_content_area{
    padding-top: 0px;
  }
  .dashboard_sidebar_menu>ul>li.opened>.submenu a.active:before, .dashboard_sidebar_menu>ul>li>a.active::before{
    width: 6px;
  }

  .dashboard_panel_count .d_icon_info {
    top: 10px;
    right: 10px;
  }
  .d_icon_info {
    width: 20px;
    height: 20px;
  }
  .dashboard_panel_count a {
    bottom: 10px;
    right: 25px;
    right: 10px;
    max-width: calc(100% - 2*10px);
    width: 100%;
    font-size: 16px;
    text-align: center;
  }




  .wallet_widget_header {
    margin-bottom: 20px;
    padding: 15px 25px;
    border-radius: 20px;
  }
  .wallet_widget{
    max-width: 100%;
  }
  .wallet_widget_header h1 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .wallet_widget_header h1 img {
    margin-right: 10px;
    width: 40px;
    height: 40px;
  }
  .wallet_widget_header ul{
    max-width: 100%;
  }
  .wallet_widget_header ul li a {
    font-size: 18px;
    padding-bottom: 10px;
  }
  .wallet_widget_header ul li a:after {
    height: 5px;
  }
  .btn_walled_send_receive {
    font-size: 18px;
    padding: 10px 20px;
  }
  .btn_walled_send_receive:before {
    width: 13px;
    height: 22px;
    margin-right: 10px;
  }
  .walled_widget_actions .content{
    padding: 20px;
  }
  .walled_widget_actions .content h3 {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 18px;
  }
  .item_price h5 {
    font-size: 16px;
    margin-bottom: 5px;
  }
  .item_price p {
    padding-left: 20px;
  }
  .item_prices_holder .item_price{
    margin-bottom: 20px;
  }
  .item_price{
    font-size: 16px;
  }
  .item_price_info {
    margin-left: 5px;
    vertical-align: -4px;
  }
  .item_price_info img {
    width: 17px;
  }

  .d_group{
    margin-bottom: 20px;
  }
  .d_group label {
    margin-bottom: 5px;
    font-size: 16px;
  }
  .d_group input {
    padding: 12px 14px;
    font-size: 16px;
    border-width: 2px;
    border-radius: 7px;
  }
  .d_number_suffix .right_controls {
    right: 10px;
    top: 0px;
  }
  .right_controls .arrows img {
    width: 20px;
    height: 20px;
  }
  .d_number_suffix .right_controls .label {
    margin-left: 10px;
    font-size: 16px;
    padding: 5px 8px;
    border-radius: 5px;
  }

  .btn.btn-sm {
    font-size: 18px;
    padding: 14px 35px;
  }
  .d_form_buttons>*{
    margin: 0px;
    margin-bottom: 10px;
    width: 100%;
    display: block;
  }

  .dashboard_content_panel h1 {
    font-size: 22px;
    margin-bottom: 15px;
  }
  .wallet_balances h4 {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .wallet_balance_item.warning {

    /*Don't change this border-width*/
    border-width: 2px;
  }
  .wallet_balance_item {
    margin-bottom: 10px;
    padding: 12px 18px;
    border-radius: 7px;
  }
  .wallet_balance_item .warning_content {
    font-size: 16px;
    padding-left: 25px;
  }
  .wallet_balance_item .warning_content:before {
    width: 15px;
    height: 15px;
    left: 0px;
    top: 2px;
  }

  .wallet_balance_item .right_text {
    font-size: 16px;
    padding: 4px 0px;
    padding-right: 25px;
    background-size: 22px 22px;
  }
  .wallet_balance_item{
    display: block;
  }
  .wallet_balance_item>*{
    margin-bottom: 10px;
  }
  .wallet_balance_item .coin_content strong{
    font-size: 16px;
  }
  .wallet_balance_item .right_text p{
    margin-bottom: 5px;
  }
  .wallet_balance_item .coin_content>img {
    display: block;
    width: 25px;
    height: 25px;
    margin-right: 20px;
  }
  .wallet_balance_item .coin_content>img{
    margin-right: 7px;
  }
  .d_form_buttons>.btn:last-child {
    margin-right: 0px;
    box-shadow: -5px 3px 3px 1px rgba(0,0,0,0.5);
  }
  .dashboard_content_panel h1{
    font-size: 20px;
  }
  .d_infoParagraph {
    font-size: 16px;
    padding: 5px 0px;
    padding-left: 25px;
    background-size: 17px 17px;
    margin-bottom: 15px;
  }
  .d_copy_text {
    font-size: 16px;
    display: block;
  }
  .d_copy_text span {
    margin-left: 0px;
    font-size: 12px;
    padding: 15px 10px;
    padding-right: 50px;
    background-size: 16px auto;
    background-position: calc(100% - 15px) center;
    border-radius: 5px;
    /*Dont change this border*/
    border-width: 2px;
  }

  .d_hr {
    height: 2px;
    margin: 20px 0px;
  }
  .d_centeredTexts h5 {
    font-size: 16px;
    margin-bottom: 12px;
  }
  .d_centeredTexts p {
    font-size: 16px;
    margin-bottom: 12px;
  }

  .dashboard_sidebar_menu .d_logo{
    padding-top: 18px;
    padding-bottom: 17px;
  }
  .dashboard_sidebar_menu .d_logo{
    width: 100% !important;
    padding-left: 15px;
    padding-right: 15px;
  }
  .dashboard_sidebar_menu .d_logo img{
    object-position: left center;
    -o-object-position: left center;
  }



  .d_illustration {
    display: block;
    position: absolute;
    position: fixed;
    left: -270px;
    left: 0px;
    top: 0px;
    width: calc(100% + 270px);
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center bottom;
    -o-object-fit: cover;
    -o-object-position: center bottom;
  }

  .d_illustration_signup{
    position: static;
    width: 100% !important;
    height: 160px;
    object-position: center -23px;
  }
  .signup_form{
    position: static;
    height: auto;
    width: 100%;
  }
  .signup_form_inner_content{
    width: 300px;
  }
  .signup_form{
    padding: 30px var(--grid_padding);
  }
  .signup_logo {
    margin-bottom: 25px;
    max-width: 150px;
  }
  .signup_form_intro_content h1 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .signup_form_intro_content p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .signup_form_intro_content{
    margin-bottom: 30px;
  }
  .signup_form_inner_content{
    width: 100%;
  }
  .signup_form_group input {
    font-size: 16px;
    padding: 15px 20px;
  }
  .signup_form_group{
    margin-bottom: 10px;
  }
  .signup_checkboxes{
    padding-top: 10px;
    margin-bottom: 20px;
  }
  .z_checkbox{
    font-size: 16px;
  }
  .z_checkbox>.box{
    width: 20px;
    height: 20px;
    margin-right: 15px;
    border-radius: 5px;
  }
  .z_checkbox.checked>.box:after{
    width: 14px;
    height: 14px;
    left: calc(50% - 7px);
    top: calc(50% - 7px);
    border-radius: 3px;
  }
  .signup_recaptcha{
    margin-bottom: 20px;
  }
  .signup_recaptcha>img{
    width: 100%;
    height: auto;
  }










}