@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;900&display=swap');


body{
  background-color: #331220;

  --font_montserrat: 'Montserrat', sans-serif;
  --grid_padding: 30px;

  --container_width: calc(1410px + 2*var(--grid_padding));

  font-family: var(--font_montserrat);

  font-size: 18px;


  cursor: default;
}




::-moz-selection{ background-color: #e31336; color: #f4b039; }
::selection { background-color: #e31336; color: #f4b039; }

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background-color: #bdb785;
}
::-webkit-scrollbar-thumb {
  background-color: #ac4a39;
}




a,.btn{
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

*>*:last-child{
  margin-bottom: 0px !important;
}


/*bootstrap start*/
.container{
  padding: 0px var(--grid_padding);
}
.row>*{
  padding: 0px var(--grid_padding);
}
.row{
  margin: 0px calc(0px - var(--grid_padding));
}

.btn{
  border: solid 9px #f2ecd1;
  -webkit-border-radius: 9px;
  border-radius: 9px;
  font-size: 22px;
  line-height: 1;
  padding: 20px 30px;
  text-transform: uppercase;
  text-shadow: 1px 1px 4px #000000;
  font-weight: 900;

  -webkit-box-shadow: 0 17px 17px 1px rgba(0,0,0,0.3);
  box-shadow: 0 17px 17px 1px rgba(0,0,0,0.3);
}
.btn_header{
  padding-top: 15px;
  padding-bottom: 15px;
}
header .btn_header{
  min-width: 290px;
}
.btn-warning{
  background-color: #e9562a;
  color: white;
}
.btn-warning:hover{
  border-color: #e9562a;
  color: #3c1e2d;
  background-color: #f4af3a;

  background-color: #e53634;
  border-color: #e53634;
  color: white;
}
.btn-warning:active{
  color: white !important;
  background-color: #e66130 !important;
  border-color: #e54732 !important;
}
.btn-success{
  background-color: #007445;
  color: #ffffff;
}
.btn-success:hover{
  background-color: #007445 !important;
  border-color: #007445 !important;
  color: white !important;
}
.btn-success:active{
  background-color: #00827a !important;
}
.btn-primary{
  background-color: #00827a;
}
.btn-primary:hover{
  background-color: #00827a !important;
  border-color: #00827a !important;
}
.btn-primary:active{
  background-color: #007445 !important;
}
.btn-danger{
  background-color: #750c39;
}
.btn-light{
  background-color: white;
  color: #331925;
  text-shadow: none !important;
}

.form-control{
  padding: 20px 35px;
  line-height: 1;
  font-size: 32px;
  color: #0f0f11;
  font-weight: 400;
}

.form-control{
  outline: none !important;
  -webkit-box-shadow: 0 0 0 0 rgba(0,0,0,0) !important;
  box-shadow: 0 0 0 0 rgba(0,0,0,0) !important;
  border: none !important;
}
.form-control{
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.btn.disabled, .btn:disabled, fieldset:disabled .btn{
  background-color: grey;
  border-color: grey;
  -webkit-box-shadow: 0 0 0 0 rgba(0,0,0,0);
  box-shadow: 0 0 0 0 rgba(0,0,0,0);
}
/*bootstrap end*/




header{
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  border:solid 10px transparent;
  border-left: none;
  border-right: none;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
header.is_for_mobile{
  display: none;
}
body.scrolled header{
  border-bottom-color: #fcf5e9;
  border-bottom-color: #735D68;
  background-color: #3f2130;

  -webkit-box-shadow: 0 20px 20px 1px rgba(0,0,0,0.1);
  box-shadow: 0 20px 20px 1px rgba(0,0,0,0.1);
}
header .col-lg-12{
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
header ul{
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
}
.header_main_logo{
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;

  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.header_main_logo img{
  display: block;
  height: 71px;
  width: auto;
}
.header_main_logo_left{
  position: static;
  margin-right: 40px;

  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.header_left_menu_set{
  display: flex;
  align-items: center;
}
.header_left_menu a{
  display: block;
  font-weight: bold;
  font-size: 20px;
  color: #f8f5ee;
  position: relative;
  padding: 15px 0px;
  text-decoration: none;
  text-transform: uppercase;
}
.header_left_menu a:after{
  content: '';
  display: block;
  height: 2px;
  width: 100%;
  left: 0px;
  bottom: 0px;
  background-color: #f8f5ee;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.header_left_menu a:not(:hover):after{
  -moz-transform: scaleX(0);
  -webkit-transform: scaleX(0);
  -o-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
}
.header_left_menu>li.active a:after{
  -moz-transform: scaleX(1);
  -webkit-transform: scaleX(1);
  -o-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}
.header_left_menu>li{
  margin-right: 55px;
}
.header_left_menu>li:last-child{
  margin-right: 0px;
}
header .container{
  max-width: 100%;
}
.header_menu_user>li{
  margin-right: var(--grid_padding);
}
.header_menu_user>li:last-child{
  margin-right: 0px;
}
.btn_icon_header{
  font-weight: bold;
  font-size: 20px;
  text-decoration: none;
  display: inline-block;
  color: #f8f5ee;
}
.btn_icon_header img{
  display: inline-block;
  height: 19px;
  width: auto;
  margin-right: 7px;
  vertical-align: -3px;
}
header{
  z-index: 10000;
}



.big_header_with_banner{
  position: relative;
  min-height: 900px;
  height: 100vh;
  display: flex;
  align-items: center;
  padding: 130px 0px;
}
.big_header_with_banner .btn{
  min-width: 400px;
}
.big_header_with_banner .container{
  position: relative;
}
.big_header_banner{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.big_header_banner>img{
  display: block;
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: auto;
  height: 100%;
  object-fit: cover;
  object-position: right top;
  -o-object-fit: cover;
  -o-object-position: right top;
}
.big_header_with_banner .container{
  max-width: 100%;
  max-width: 1750px;
}
.big_header_with_banner .col-lg-12{
  display: flex;
  justify-content: flex-end;
}
.big_header_with_banner .content{
  width: 100%;
  max-width: 665px;
  text-align: center;
  margin-top: -150px;
}
.big_header_with_banner .content>img{
  display: block;
  width: 100%;
  margin-bottom: 60px;
}
.big_header_with_banner .content>h1{
  text-transform: uppercase;
  font-size: 125px;
  font-weight: 600;
  text-align: center;
  color: #fab132;
  margin-bottom: 60px;
  line-height: 1;
  text-shadow: 0px 5px 0px #f06230;
}
.big_header_with_banner .content>h1>*{
  display: block;
  width: 100%;

}
.big_header_with_banner .content>h1>strong{
  font-size: 70%;
  font-weight: 900;
  color: #ed1a39;
  margin-bottom: 5px !important;
  text-shadow: 0px 5px 0px #fab132;
  
}
.big_header_with_banner .content>h1>i{
  font-size: 40%;
  font-weight: 400;
  margin-bottom: 10px;
  color: #fffac4;
  text-shadow: 0px 5px 0px transparent;
  font-style: normal;
}


.liberty_banner{
  display: flex;
  align-items: center;
}
.liberty_banner .container{
  position: relative;
  max-width: var(--container_width);
  z-index: 4;
}
.liberty_banner>img{
  display: block;
  position: absolute;
}
.liberty_banner .liberty_banner_content{
  background-color: #96bcaf;

  -webkit-border-radius: 80px;
  border-radius: 80px;

  padding: 40px 40px;
  padding-top: 115px;
  padding-bottom: 90px;

  text-align: center;
}
.liberty_banner .liberty_banner_content h1,
.liberty_banner .liberty_banner_content h2,
.liberty_banner .liberty_banner_content h3{
  font-size: 100px;
  font-size: 80px;
  color: #f8f5ee;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: -5px;
  text-shadow: 0px 5px 0px #bdb785;
  margin-bottom: 25px;
  line-height: 1.1;
}
.liberty_banner_content>div{
  margin-bottom: 35px;
}
.liberty_banner .liberty_banner_content .btn{
  min-width: 300px;
}
.liberty_banner .liberty_banner_content>*{
  z-index: 6;
  position: relative;
}
.liberty_banner{
  overflow: hidden;
  padding-top: 285px;
  padding-bottom: 365px;
  position: relative;
}
.liberty_banner .right_big_circle{
  right: 0px;
  top: 50%;

  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  height: 1062px;
  width: auto;
  z-index: 2;
}
.liberty_banner .center_big_red_circle{
  bottom: 5px;
  right: 255px;
  height: 1046px;
  width: auto;
  z-index: 1;
}
.liberty_banner_content{
  position: relative;
}
.liberty_banner_content .zraci{
  z-index: 5;
  position: absolute;
  display: block;
  width: 100vw;
  height: auto;

  left: calc(50% - 50vw);
  top: calc(50% + 100px);

  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  object-fit: contain;
  object-position: center;
  -o-object-fit: contain;
  -o-object-position: center;
}
.liberty_banner .left_circle{
  height: 337px;
  width: auto;
  left: 0px;
  top: calc(50% - 400px);
}
.liberty_banner .left_circle_small{
  left: 0px;
  height: 160px;
  width: auto;

  top: calc(50% + 80px);
}
.liberty_banner{
  background-image: url('../Images/stars-set-1.png');
  background-repeat: repeat-y;
  background-position: left top;
  background-size: auto 1200px;
}




.content_photo{
  padding: 195px 0px;
  position: relative;
}
.content_photo.planets-and-stars:before{
  background-size: 100% auto;
  background-repeat: repeat-y;
  background-position: center top;
  background-image: url('../Images/background-planets-stars.jpg');
}
.content_photo .content_top{
  margin-bottom: 55px;
  text-align: center;
  color: white;
}
.content_photo .content_top h2,
.content_photo .content_top h3{
  margin-bottom: 25px;
  font-size: 48px;
  font-weight: 900;
  text-shadow: 0px 4px 0px #ac4a39;
  text-transform: uppercase;
}
.content_photo .content_top p{
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 25px;
}
.content_photo:before{
  position: absolute;
  content: '';
  display: block;
  right: 0px;
  top: 0px;
  height: 100%;
  width: calc(100% - var(--grid_padding));
  border:solid 25px #fcf5e9;
  border-right: none;

  -webkit-border-radius: 100px 0 0 100px;
  border-radius: 100px 0 0 100px;

  background-size: cover;
  background-image: url('../Images/texture-red.jpg');
}
.content_photo .container{
  max-width: var(--container_width);
  position: relative;
}
.content_photo .inner_content{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}
.content_photo .inner_content{
  color: #fcf5e9;
}
.content_photo .inner_content h5{
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 25px;
  text-transform: uppercase;
  letter-spacing: 11px;
}
.content_photo .inner_content h2,
.content_photo .inner_content h3{
  color: white;
  font-size: 72px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: -4px;
  margin-bottom: 25px;
  text-shadow: 0px 5px 0px #ac4a39;
  line-height: 1;
}
.content_photo .inner_content p{
  font-size: 22px;
  font-weight: 600;
}
.content_photo .inner_content>*{
  width: calc(50% - var(--grid_padding)/2);
}
.content_photo .inner_content>.content_full_width{
  width: 100%;
}
.content_photo .centered_text{
  text-align: center;
}
.content_photo .inner_content>img{
  display: block;
}
.content_photo .row{
  margin-bottom: 95px;
}
.list_yellow_boxes{
  margin: 0px;
  padding: 0px;
  list-style: none;

  display: grid; 
  grid-template-columns: 1fr 1fr 1fr; 
  grid-template-rows: 1fr; 
  gap: calc(2*var(--grid_padding)) var(--grid_padding); 
  grid-template-areas: 
    ". . ."; 

    position: relative;
    z-index: 2;
}
.list_yellow_boxes li{
  background-color: #fab133;
  color: #1c191d;
  font-weight: 600;
  font-size: 22px;
  text-align: center;

  border: solid 20px #fff9ef;

  padding: 30px 20px;
  padding-top: 70px;
  padding-bottom: 50px;

  -webkit-border-radius: 80px;
  border-radius: 80px;
}
.list_yellow_boxes li>span{
  display: block;
}
.list_yellow_boxes h4{
  margin-bottom: 15px;
  color: #171519;
  font-size: 28px;
  font-weight: 900;
  text-shadow: 0px 5px 0px #ac4a39;
  text-transform: uppercase;
}
.list_yellow_boxes.list_box_posts h4{
  text-transform: none;
}
.list_yellow_boxes.list_box_posts li{
  background-color: #27111b;
  background-image: url('../Images/logo-fire.png');
  background-position: center 20%;
  background-size: 55% auto;
  background-repeat: no-repeat;
  background-image: url('../Images/livberty_logo.png');
  background-image: url('../Images/pdg_logo@2x-2.png');
  background-position: center 30%;
  background-size: 75% auto;
  
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.list_yellow_boxes.list_box_posts li:hover{
  background-color: #a16776;
}
.list_yellow_boxes li>span.logo{
  display: inline-block;
  position: relative;
  margin-bottom: 60px;
}
.list_yellow_boxes li>span.logo>img{
  display: block;
  position: relative;
  width: 90px;
  height: 90px;

  object-fit: contain;
  object-position: center;
  -o-object-fit: contain;
  -o-object-position: center;
  z-index: 2;
}
.list_yellow_boxes li>span.logo .logo_background,
.list_yellow_boxes li>span.logo:before{
  content: '';
  display: block;
  position: absolute;
  width: 170px;
  height: 170px;
  background-size: 100% 100%;
  background-position: center;
  background-image: url('../Images/icon-background.png');


  left: calc(50% - 170px/2);
  top: calc(50% - 170px/2);

  z-index: 1;
}
.list_yellow_boxes li>span.logo:before{
  background-image: url('../Images/icon-background-green.png');
  
  width: 190px;
  height: 190px;
  left: calc(50% - 190px/2);
  top: calc(50% - 190px/2);

  opacity: 0.62;

  filter: blur(25px);
  -webkit-filter: blur(25px);
}
.list_yellow_boxes li>span.logo:after{
  content: '';
  display: block;
  position: absolute;
  width: 200px;
  height: 200px;
  background-size: 100% 100%;
  background-position: center;
  background-image: url('../Images/icon-background-effect.png');

  left: calc(50% - 200px/2);
  top: calc(50% - 200px/2);

  z-index: 0;
}
.list_yellow_boxes{
  margin-bottom: 50px;
  margin-bottom: 110px;
  position: relative;
}
.list_yellow_boxes li{
  -webkit-box-shadow: 0 6px 0 1px #d2cbc2;
  box-shadow: 0 6px 0 1px #d2cbc2;
}

.our_missing_buttons{
  text-align: center;
  position: relative;
  z-index: 3;
}
.our_missing_buttons>.btn{
  min-width: 300px;
}

.content_photo .row.minus_margin{
  margin-bottom: -405px !important;
}
.content_photo{
  margin-bottom: 385px;
  position: relative;
}
.content_photo:after{
  content: '';
  display: block;
  position: absolute;
  left: 0px;
  width: 100%;
  height: 385px;
  top: 100%;

  background-repeat: repeat-y;
  background-position: center;
  background-image: url('../Images/starts-set-2.png');
  background-size: auto 100%;
  z-index: 0;
}



.content_centered .container{
  max-width: var(--container_width);
}
.content_centered .content{
  text-align: center;
  position: relative;
}
.content_centered .content h5{
  font-size: 20px;
  color: #fcf5e9;
  letter-spacing: 11px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: bold;
}
.content_centered .content h2,
.content_centered .content h3{
  font-size: 80px;
  color: white;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: -3px;
  line-height: 1;

  text-shadow: 0px 5px 0px #ac4a39;
}
.content_centered .content{
  margin-bottom: 65px;
}
.content_centered{
  padding-top: 115px;
  padding-bottom: 140px;
  position: relative;
}
.content_centered:before{
  content: '';
  display: block;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: calc(100% - 70px);

  border: solid 25px #fcf5e9;
  border-left: none;

  -webkit-border-radius: 0 100px 100px 0;
  border-radius: 0 100px 100px 0;

  background-size: cover;
  background-image: url('../Images/texture-red-2.jpg');
}


.list_box_posts li{
  position: relative;
  padding: 30px 25px;
  padding-top: 365px;
  overflow: hidden;
}
.list_box_posts li>img{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
  -o-object-fit: cover;
  -o-object-position: center top;

  filter: sepia(100%);
  -webkit-filter: sepia(100%);
  -moz-filter: sepia(100%);

  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.list_box_posts li:hover img{
  filter: sepia(0%);
  -webkit-filter: sepia(0%);
  -moz-filter: sepia(0%);
}
.list_box_posts li>*{
  display: block;
  position: relative;
  text-align: left;
}
.list_box_posts li{
  cursor: pointer;
}
.list_box_posts li>span{
  position: relative;
  z-index: 4;
}
.list_box_posts li span.date{
  font-size: 14px;
  font-weight: 400;
  color: #fff9ef;
  margin-bottom: 15px;
}
.list_box_posts li span h4{
  text-shadow: 0px 5px 0px rgba(0,0,0,0);
  font-weight: 900;
  color: #fff9ef;
  font-size: 24;
}
.list_box_posts li:after{
  display: block;
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-size: 100% auto;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-image: url('../Images/post-thumb-background.png');
  z-index: 3;
}



.newsletter{
  padding-top: 245px;
  padding-bottom: 350px;
  position: relative;
  margin-bottom: 70px;
}
.newsletter .container{
  position: relative;
}
.newsletter .banner_holder{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.newsletter .banner_holder img{
  display: block;
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 68%;
  height: 776px;
  object-fit: cover;
  object-position: right top;
  -o-object-fit: cover;
  -o-object-position: right top;
}
.newsletter .html_content h5{
  font-size: 20px;
  font-weight: bold;
  color: #fcf5e9;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 11px;
}
.newsletter .html_content h2,
.newsletter .html_content h3{
  font-weight: 900;
  color: #fcf5e9;
  font-size: 80px;
  text-transform: uppercase;
  letter-spacing: -2px;
  line-height: 1;

  text-shadow: 0px 5px 0px #ac4a39;
}
.newsletter .html_content p{
  font-size: 22px;
  color: #fcf5e9;
  font-weight: bold;
}
.newsletter .html_content{
  margin-bottom: 35px;
}
.newsletter .container{
  max-width: calc(1560px + 2*var(--grid_padding));
}
.newsletter .col-lg-12{
  display: flex;
  justify-content: flex-end;
}
.newsletter_content{
  width: 100%;
  max-width: 770px;
}
.newsletter{
  background-size: auto 735px;
  background-size: contain !important;
  background-repeat: repeat-y;
  background-repeat: repeat !important;
  background-position: center;
  background-image: url('../Images/stars-set-3.png');

  margin-bottom: 70px;
}


footer{
  padding: 45px 0px;
}
footer .col-lg-12{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer_main_link{
  display: block;
}
.footer_main_link img{
  display: block;
  width: 269px;
  height: auto;
}
footer .container{
  max-width: var(--container_width);
}
.footer_social_icons{
  padding: 0px;
  margin: 0px;
  list-style: none;
}
.footer_social_icons{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.footer_social_icons a{
  display: block;
}
.footer_social_icons a>img{
  display: block;
  width: 52px;
  height: 52px;
}
.footer_social_icons>li{
  margin-right: 10px;
}
.footer_social_icons>li:last-child{
  margin-right: 0px;
}


.big_header_with_content{
  height: 100vh;
  min-height: 935px;

  padding-top: 190px;
  padding-bottom: 160px;

  display: flex;
  align-items: center;

  position: relative;
}
.big_header_with_content.add_margin{
  margin-bottom: 120px;
}
.big_header_with_content .container{
  max-width: var(--container_width);
  position: relative;
}
.big_header_with_content .col-lg-12{
  display: flex;
  justify-content: flex-end;
}
.big_header_with_content .content{
  max-width: 800px;
}
.big_header_with_content .content h1,
.big_header_with_content .content h2{
  font-size: 72px;
  font-weight: 900;
  color: #fcf5e9;

  text-shadow: 3px 5px 0px #784D2E;
  line-height: 1;
  margin-bottom: 35px;

  text-transform: uppercase;
}
.big_header_with_content .content,
.big_header_with_content .content p{
  font-size: 22px;
  font-weight: 600;
  color: #fcf5e9;
  margin-bottom: 25px;
}
.big_header_with_content .rockets_holder{
  position: absolute;
  left: 0px;
  top: auto;
  bottom: -415px;
  width: 100%;
  height: 100%;
}
.big_header_with_content .rockets_holder .rockets{
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 50%;
  height: 1109px;
  background-size: auto 100%;
  background-position: right bottom;
  background-repeat: no-repeat;
  background-image: url('../Images/rockets-2.png');
}
.big_header_with_content.the_people_look .rockets_holder .rockets{
  background-image: url('../Images/the-people-rockets.png');
}
.big_header_with_content .rockets_holder:after{
  display: block;
  content: '';
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 50%;
  height: 1109px;
  background-repeat: repeat-x;
  background-position: center bottom;
  background-size: auto 457px;
  background-image: url('../Images/rockets-2-line.png');
}
.big_header_with_content .btn{
  min-width: 260px;
}
.big_header_with_content .the_planet{
  display: block;
  position: absolute;
  left: 0px;
  top: 175px;
  width: auto;
  height: 310px;

  object-fit: contain;
  object-position: left center;
  -o-object-fit: contain;
  -o-object-position: left center;
}
.big_header_with_content>.the_planet_red{
  left: auto !important;
  right: 0px !important;
  top: 20% !important;
}
.big_header_with_content{
  background-size: cover;
  background-position: center top;
  background-image: url('../../Assets/Images/background-second-page.png');
}
/*.big_header_with_content.margin_bottom_85{
  margin-bottom: 85px;
}*/
.big_header_with_content .stars{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-size: contain;
  background-position: center;
  background-image: url('../Images/stars-set-4.png');
}
.big_header_with_content.the_green_planet .rockets_holder:after,
.big_header_with_content.the_green_planet .the_planet{
  display: none;
}
.big_header_with_content.the_green_planet .rockets_holder{
  bottom: auto;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: url('../Images/the-green-planet.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;
}
.big_header_with_content.the_green_planet .rockets_holder .rockets{
  display: none;
}
.big_header_with_content.is_for_post .col-lg-12{
  justify-content: space-between;
  align-items: flex-start;
}
.big_header_with_content.is_for_post.justify_post_content_to_the_right .col-lg-12{
  /*justify-content: flex-end;*/
}
.big_header_with_content.is_for_post .col-lg-12>*{
  width: 45%;
}
.big_header_with_content.is_for_post .col-lg-12>img{
  max-height: 70vh;
  object-fit: cover;
  object-position: center top;
  -o-object-fit: cover;
  -o-object-position: center top;
  display: block;

  border: solid 20px #fff9ef;
  border-radius: 80px;
  box-shadow: 6px 6px 0 1px #d2cbc2;
}
.big_header_with_content.is_for_post .col-lg-12>img.post_default_image{
  width: 40%;
  border-radius: 0px;
  box-shadow: 6px 6px 0 1px transparent;
  border: none;

  -webkit-filter: drop-shadow(5px 5px 0px #311925);
  filter: drop-shadow(5px 5px 0px #311925)
}




.content_tv_circle{
  overflow: hidden;
}
.content_tv_circle .circle_content{
  background-image: url('../Images/eclipse-yellow.png');
  background-size: 100% calc(100% + 280px);
  background-repeat: no-repeat;
  width: 100%;
  max-width: 1230px;
  margin-left: auto;
  margin-right: auto;
}
.content_tv_circle .container{
  max-width: var(--container_width);
}
.content_tv_circle .the_tv{
  width: 679px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 35px;
  position: relative;
}
.content_tv_circle .the_tv:before{
  content: '';
  display: block;
  position: absolute;
  left: -320px;
  width: calc(100% + 2 * 320px);
  top: -130px;
  height: calc(100% + 2 * 130px);

  background-size: 100% 100%;
  background-position: center;
  background-image: url('../Images/tv-zraci.png');
}
.content_tv_circle .the_tv img{
  display: block;
  width: 100%;
  height: auto;
  position: relative;
}
.content_tv_circle .content{
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 755px;
  font-size: 22px;
  font-weight: 600;
  color: #2b2525;

  text-align: center;
}
.content_tv_circle .content p{
  margin-bottom: 30px;
}
.buttons_holder{
  text-align: center;
  padding-bottom: 45px;
}
.buttons_holder>*{
  margin: 0px 12px;
  min-width: 315px;
}
.content_tv_circle h2{
  text-align: center;
  font-weight: 900;
  font-size: 100px;
  line-height: 1;
  color: #fcf5e9;
  text-transform: uppercase;
  margin-bottom: 25px;
  text-shadow: 0px 5px 0px #78482b;
  letter-spacing: -4px;
}
.content_tv_circle.title_smaller h2{
  font-size: 72px;
}
.content_tv_circle{
  padding-top: 140px;
  background-size: calc(100% - 2 * 50px) auto;
  background-position: 50px center;
  background-repeat: repeat-y;
  background-image: url('../Images//stars-set-5.png');
}
.content_tv_circle .circle_content.just_tv{
  padding: 55px 0px;
  max-width: 1040px;
}

.icons_content{
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.icons_content li{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 100px;
}
.icons_content>li:nth-child(2n){
  flex-direction: row-reverse;
}
.icons_content li .image{
  display: flex;
  width: 338px;
  height: 338px;
  align-items: center;
  justify-content: center;
  position: relative;
}
.icons_content li .image img{
  display: block;
  width: 138px;
  height: 138px;

  -webkit-border-radius: 69px;
  border-radius: 69px;
  position: relative;

  -webkit-box-shadow: 0 6px 0 1px #d2cbc2;
  box-shadow: 0 6px 0 1px #d2cbc2;
}
.icons_content li .image::before{
  position: absolute;
  content: '';
  display: block;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-position: center;
  background-image: url('../Images/icon-background-3.png');
}
.icons_content li .content{
  display: block;
  width: calc(100% - 338px - 36px);
}
.icons_content li span{
  display: block;
}
.icons_content li h4{
  font-size: 36px;
  color: #ffffff;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: -2px;
  line-height: 1;
  margin-bottom: 20px;

  text-shadow: 0px 5px 0px #ac4a39;
}
.icons_content li p{
  margin-bottom: 25px;
  font-size: 22px;
  font-weight: 600;
  color: #fcf5e9;
}
.icons_content li .btn{
  min-width: 320px;
}


.content_centered_2{
  padding-top: 130px;
  padding-bottom: 135px;
  text-align: center;
}
.content_centered_2 .container{
  max-width: 100%;
}
.content_centered_2 h1,
.content_centered_2 h2,
.content_centered_2 h3{
  font-size: 100px;
  font-weight: 900;
  color: #fcf5e9;
  line-height: 1;
  margin-bottom: 45px;
  text-shadow: 0px 5px 0px #78482b;
  text-transform: uppercase;
  letter-spacing: -6px;
}
.content_centered_2 p{
  font-size: 22px;
  font-weight: 400;
  color: #fcf5e9;
  margin-bottom: 30px;
}
.content_centered_2 .container{
  position: relative;
}
.content_centered_2{
  position: relative;
}
.content_centered_2 .left_circle{
  display: block;
  height: 613px;
  width: auto;
  position: absolute;
  left: 0px;
  top: -20px;
}
.content_centered_2 .right_circle{
  display: block;
  height: 337px;
  width: auto;
  position: absolute;
  right: 0px;
  top: 30px;
}


.x3_yellow_boxes .container{
  max-width: var(--container_width);
}
.x3_yellow_boxes{
  padding-bottom: 150px;
}
.content_photo.padding-bottom-for-x3-boxes{
  padding-bottom: 405px;
}
.x3_yellow_boxes.margin-top-go-up{
  margin-top: -355px;
}



.logos_box{
  padding-top: 85px;
}
.logos_box .container{
  max-width: var(--container_width);
}
.logox_box_content{
  padding: 55px 90px;
  padding-bottom: 65px;
  background-color: #fcf5e9;

  -webkit-border-radius: 50px;
  border-radius: 50px;
}
.logos_box .content{
  text-align: center;
  margin-bottom: 40px;
}
.logos_box .content h2,
.logos_box .content h3{
  margin-bottom: 25px;
  color: #2b2525;
  font-size: 48px;
  font-weight: 900;
  letter-spacing: -3px;
  text-transform: uppercase;
  text-shadow: 3px 5px 0px #fcddaf;
}
.logos_box .content p{
  font-weight: 600;
  font-size: 22px;
  color: #2b2525;
  margin-bottom: 25px;
}
.logox_box_content .the_logos{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.logox_box_content .the_logos img{
  display: block;
  height: 87px;
  width: auto;
  max-width: 250px;

  object-fit: contain;
  object-position: center;
  -o-object-fit: contain;
  -o-object-position: center;
  margin: 30px 10px !important;
}



.content_boxes{
  margin-bottom: 100px;
}
.content_box_item{
  text-align: center;
  border: solid 10px #fff9ef;

  -webkit-border-radius: 70px;
  border-radius: 70px;

  margin-bottom: 100px;

  background-image: url('../Images/background-brown.jpg');
  background-size: cover;
  background-position: center;

  padding: 90px var(--grid_padding);
  min-height: 505px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #fcf5e9;
}
.content_box_item>div{
  width: 100%;
  max-width: 960px;
}
.content_box_item h2,
.content_box_item h3{
  font-size: 48px;
  font-weight: 900;
  text-transform: uppercase;
  color: white;
  margin-bottom: 60px;
  line-height: 1;
  letter-spacing: -3px;
  text-shadow: 0px 5px 0px #ac4a39;
}
.content_box_item p{
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: 600;
}



.login_form{
  padding-top: 50px;
  padding-bottom: 215px;
  min-height: 100vh;

  background-size: cover;
  background-position: center top;
  background-image: url('../Images/login-the-background.jpg');

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
}
.the_astronauts_on_the_sides{
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: cover;
  object-position: center bottom;
  -o-object-fit: cover;
  -o-object-position: center bottom;
  display: block;
}
.login_bottom_area{
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: auto;
  display: block;
}
.login_moon{
  position: absolute;
  display: block;

  width: 41px;
  height: auto;

  left: 1230px;
  top: 25px;
}
.login_right_planets{
  display: block;
  position: absolute;

  right: 0px;
  top: 30px;

  width: 118px;
  height: auto;
}
.login_the_stars{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;

  background-size: cover;
  background-image: url('../Images/login-the-stars.png');
}
.login_form_content{
  width: 100%;
  position: relative;
}
.login_form_ufo{
  display: block;
  width: 100%;
  max-width: 332px;
  height: auto;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
}
.login_form_inputs{
  width: 100%;
  max-width: 600px;
  background-color: #fcf5e9;
  padding: 60px;
  padding-bottom: 25px;
  margin-left: auto;
  margin-right: auto;

  -webkit-border-radius: 50px;
  border-radius: 50px;
}
.login_form_inputs .login_logo{
  display: block;
  margin: 0 auto;
  margin-bottom: 40px;
  width: 248px;
  height: auto;
}
.login_form_control_holder .form-control{
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background-color: white;
  color: #25111a;
  font-family: var(--font_montserrat);
  font-size: 18px;
  padding: 33px 30px;
  line-height: 1;
}
.login_form_control_holder.have_icon .form-control{
  padding-right: calc(50px + 30px - 20px);
}
.login_form_control_holder.have_icon{
  position: relative;
}
.login_form_control_holder.have_icon>img{
  position: absolute;
  display: block;
  height: 24px;
  width: auto;
  left: calc(100% - 50px);
  top: calc(50% - 24px/2);
}
.login_form_control_holder{
  margin-bottom: 25px;
}
.login_big_button_holder{
  text-align: center;
  padding-top: 10px;
  margin-bottom: 25px;
}
.login_big_button_holder>.btn{
  min-width: 290px;
  -webkit-box-shadow: -10px 5px 5px 1px rgba(0,0,0,0.4);
  box-shadow: -10px 5px 5px 1px rgba(0,0,0,0.4);
}
.login_link{
  font-weight: 400;
  color: #00827a;
  text-decoration: none;
}
.login_link:hover{
  text-decoration: underline;
}
.login_forgot_link{
  text-align: center;
  margin-bottom: 75px;
}
.login_big_button_holder .error{
  margin-bottom: 5px;
  color: #e9562a;
}



.dashboard{
  height: 100vh;

  background-image: url('../Images/dashboard-background.jpg');
  background-size: cover;
  background-position: center top;

  position: relative;
}
.dashboard_sidebar_menu{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 270px;
  height: 100%;
  background-color: #e4dbcb;
}
.dashboard_sidebar_menu .d_logo{
  display: block;
  width: 113px;
  margin: 0 auto;
  padding-top: 25px;
  padding-bottom: 30px;
}
.dashboard_sidebar_menu .d_logo img{
  width: 100%;
  height: 27px;
  object-fit: contain;
  object-position: center;
  -o-object-fit: contain;
  -o-object-position: center;
}

.dashboard_sidebar_menu ul{
  padding: 0px;
  margin: 0px;
  list-style: none;
}
.dashboard_sidebar_menu a{
  display: block;
  text-decoration: none;
  font-size: 18px;
  color: #25111a;
  padding-right: 15px;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
}
.dashboard_sidebar_menu li a>img{
  width: 24px;
  height: 22px;
  object-fit: contain;
  object-position: center;
  -o-object-fit: contain;
  -o-object-position: center;
  margin-right: 15px;

  vertical-align: -3px;
}
.dashboard_sidebar_menu ul{
  overflow: auto;
}
.dashboard_sidebar_menu>ul>li>a{
  padding-left: 15px;
  padding-right: 15px;
  font-weight: bold;
}
.dashboard_sidebar_menu>ul>li>.submenu{
  width: calc(100% - 15px - 15px);
  width: 100%;
  margin: 0 auto;
  display: none;
}
.dashboard_sidebar_menu>ul>li>.submenu a{
  padding: 10px 0px;
  padding-left: 37px;
  padding-left: 52px;
}
.dashboard_sidebar_menu>ul>li.opened{
  background-color: #c0b5a3;
  margin-bottom: 15px;
}
.dashboard_sidebar_menu>ul>li.opened>.submenu{
  display: block;
  padding-bottom: 10px;
  padding-bottom: 20px;
}
.dashboard_sidebar_menu li{
}
.dashboard_sidebar_menu>ul>li>a:hover,
.dashboard_sidebar_menu>ul>li>a.active{
  background-color: #c0b5a3;
}
.dashboard_sidebar_menu>ul>li.opened>.submenu a:hover,
.dashboard_sidebar_menu>ul>li.opened>.submenu a.active{
  /*background-color: #e4dbcb;
  background-color: #d7d7d7;
  background-color: #fcf5e9;*/
  background-color: #96bcaf;
}


section.photo{
  margin: 5vw 0px;
}
section.photo img{
  display: block;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: center top;
  -o-object-fit: cover;
  -o-object-position: center top;

}

.dashboard_content_area{
  padding: 95px 80px;
  padding-right: 120px;
  overflow: auto;
  height: 100vh;
  width: calc(100% - 270px);
  margin-left: 270px;

  /*background-color: rgba(255,0,0,0.3);
  border: solid 1px red;*/
}

.dashboard_panel_count{
  background-color: #fcf5e9;
  -webkit-border-radius: 50px;
  border-radius: 50px;

  padding: 35px 52px;
  color: #25111a;
}
.dashboard_panel_count h4{
  font-size: 24px;
  font-weight: bold;
  margin: 0px;
}
.dashboard_panel_count .count{
  padding: 40px 0px;
  text-align: center;
  font-size: 56px;
  font-weight: bold;
}
.dashboard_panel_count .count small{
  font-weight: 400;
  font-size: 18px;
  display: block;
  line-height: 1;
}

.dashboard_counts_grid .col-lg-12{
  display: grid; 
  grid-template-columns: 1fr 1fr 1fr; 
  grid-template-rows: 1fr; 
  gap: 32px 24px; 
  grid-template-areas: 
    ". . ."; 
}
.dashboard_content_area .container{
  margin-bottom: 50px;
}

.dashboard_panels_x2_areas>div{
  margin-bottom: 20px;
  background-color: #e4dbcb;

  -webkit-border-radius: 15px;
  border-radius: 15px;
}
.dashboard_panels_x2_areas .area_1{
  min-height: 95px;
}
.dashboard_panels_x2_areas .area_2{
  min-height: 170px;
}

.dashboard_content_panel{
  background-color: #e4dbcb;

  -webkit-border-radius: 15px;
  border-radius: 15px;

  min-height: 575px;
}


.dashboard_sidebar_menu>ul>li.opened>.submenu a.active,
.dashboard_sidebar_menu>ul>li>a.active{
  position: relative;
}
.dashboard_sidebar_menu>ul>li.opened>.submenu a.active:before,
.dashboard_sidebar_menu>ul>li>a.active::before{
  height: 100%;
  content: '';
  width: 6px;
  left: 0px;
  top: 0px;
  display: block;
  position: absolute;
  background-color: #00827a;
}

.dashboard_content_area .container{
  max-width: 100%;
}
.dashboard_content_panel{
  padding: 40px 50px;
  padding-bottom: 50px;
  height: auto !important;
  min-height: 0px !important;
}

.dashboard_content_panel h1{
  font-size: 24px;
  font-weight: bold;
  color: #25111a;
  margin-bottom: 20px;
}
.d_infoParagraph{
  font-size: 16px;
  font-weight: 400;
  color: #25111a;
  padding: 5px 0px;
  padding-left: 35px;

  background-repeat: no-repeat;
  background-position: left center;
  background-size: 26px 26px;
  background-image: url('../Images/icon-danger.png');

  margin-bottom: 25px;
}
.d_infoParagraph a{
  color: inherit;
  text-decoration: underline;
}
.d_infoParagraph a:hover{
  text-decoration: none;
}

.d_copy_text{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.d_copy_text{
  font-weight: bold;
  font-size: 16px;
  color: #25111a;
}
.d_copy_text span{
  display: block;
  margin-left: 18px;
  font-weight: 400;
  font-size: 16px;
  color: #25111a;

  background-color: white;
  padding: 10px 18px;

  padding-right: 50px;
  background-size: 16px auto;
  background-position: calc(100% - 15px) center;
  background-repeat: no-repeat;
  background-image: url('../Images/icon-d-copy.png');

  -webkit-border-radius: 7px;
  border-radius: 7px;

  cursor: pointer;

  border: solid 2px #d7d7d7;
}
.d_copy_text input{
  padding: 0px;
  height: 0px;
  border: none;
  visibility: hidden;
  display: block;
}
.d_copy_text.copied span{
  background-color: #00827a;
}

.d_hr{
  height: 3px;
  background-color: #331925;
  border: none;
  margin: 30px 0px;
  opacity: 1;
}

.d_centeredTexts{
  text-align: center;
}
.d_centeredTexts h5{
  font-size: 18px;
  font-weight: bold;
  color: #25111a;
  margin-bottom: 15px;
}
.d_centeredTexts p{
  font-weight: 400;
  color: #25111a;
  font-size: 18px;
  margin-bottom: 15px;
}

.dashboard_content_area{
  position: relative;
}
.dashboard_content_area .container{
  position: relative;
}

.d_illustration{
  display: block;
  position: absolute;
  position: fixed;
  left: -270px;
  left: 0px;
  top: 0px;
  width: calc(100% + 270px);
  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center bottom;
  -o-object-fit: cover;
  -o-object-position: center bottom;
}

.dashboard_sidebar_menu{
  z-index: 100;
}

.dashboard_sidebar_menu>ul>li>.submenu{
  width: 100% !important;
}

.dashboard_sidebar_menu{
  position: fixed;
}
.dashboard_sidebar_menu>ul{
  height: calc(100vh - 135px - 80px);
}
.dashboard_content_panel.light{
  background-color: #fcf5e9;
}

.dashboard_panel_count{
  position: relative;
}
.dashboard_panel_count a{
  display: block;
  position: absolute;
  bottom: 25px;
  right: 25px;
  max-width: calc(100% - 2*25px);
  color: #00827a;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  text-transform: uppercase;
}
.dashboard_panel_count a:hover{
  text-decoration: underline;
}

.d_icon_info{
  width: 25px;
  height: 25px;

  object-fit: contain;
  object-position: center;
  -o-object-fit: contain;
  -o-object-position: center;

}
.dashboard_panel_count .d_icon_info{
  display: block;
  position: absolute;
  top: 35px;
  right: 30px;
}


.wallet_widget{
  max-width: 590px;
}
.wallet_widget_header{
  margin-bottom: 20px;
  padding: 25px 45px;
  background-color: #fcf5e9;

  -webkit-border-radius: 50px;
  border-radius: 50px;
}
.wallet_widget_header h1{
  font-size: 24px;
  font-weight: bold;
  color: #25111a;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 25px;
}
.wallet_widget_header h1 img{
  display: block;
  object-fit: contain;
  object-position: center;
  -o-object-fit: contain;
  -o-object-position: center;

  margin-right: 15px;

  width: 44px;
  height: 44px;
}
.wallet_widget_header ul{
  padding: 0px;
  margin: 0px;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 360px;
}
.wallet_widget_header ul li a{
  display: block;

  font-size: 18px;
  text-decoration: none;
  color: #25111a;
  font-weight: 400;

  padding-bottom: 10px;

  position: relative;
}
.wallet_widget_header ul li a:after{
  content: '';
  display: block;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 5px;
  background-color: #331925;

  -moz-transform: scaleX(0);
  -webkit-transform: scaleX(0);
  -o-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.wallet_widget_header ul li a.active{
  font-weight: bold;
}
.wallet_widget_header ul li a:hover:after,
.wallet_widget_header ul li a.active:after{
  -moz-transform: scaleX(1);
  -webkit-transform: scaleX(1);
  -o-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}

.walled_widget_actions{
  background-color: #fcf5e9;
}
.walled_widget_actions .content{
  padding: 50px;
}


.btn_walled_send_receive{
  font-weight: bold;
  color: white;
  font-size: 20px;

  padding: 25px 25px;

  background-color: #0f0f11;

  text-align: center;
  text-transform: none;
  text-decoration: none;

  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn_walled_send_receive:before{
  content: '';
  display: block;
  width: 13px;
  height: 27px;
  margin-right: 15px;

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../Images/icon-arrow-up.png');
}
.btn_walled_send_receive.send{
  background-color: #c18215;
}
.btn_walled_send_receive.receive{
  background-color: #0a572c;
}
.btn_walled_send_receive.receive:before{
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.walled_widget_x2_buttons{
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.walled_widget_x2_buttons>*{
  display: block;
  display: flex;
  width: 50%;
}


.btn.btn-sm{
  font-size: 20px;
  padding: 14px 35px;
}

.walled_widget_actions{
  -webkit-border-radius: 50px;
  border-radius: 50px;
}

.walled_widget_x2_buttons>*:first-child{
  -webkit-border-radius: 50px 0 0 0;
  border-radius: 50px 0 0 0;
}
.walled_widget_x2_buttons>*:last-child{
  -webkit-border-radius: 0 50px 0 0;
  border-radius: 0 50px 0 0;
}
.walled_widget_actions .content h3{
  margin-bottom: 30px;
  font-weight: bold;
  font-size: 20px;
}

.item_prices_holder .item_price{
  margin-bottom: 20px;
}
.item_prices_holder{
  margin-bottom: 45px;
}
.item_price{
  font-weight: 400;
  font-size: 18px;
}
.item_price h5{
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}
.item_price p{
  padding-left: 30px;
}
.item_price_info{
  display: inline-block;
  text-decoration: none;
  margin-left: 5px;
  vertical-align: -4px;
}
.item_price_info img{
  display: block;
  width: 17px;
  height: auto;
}


.d_group{
  margin-bottom: 25px;
}
.d_group label{
  margin-bottom: 5px;
  font-size: 16px;
  color: #25111a;
  font-weight: bold;
}
.d_group input{
  padding: 12px 14px;
  font-weight: 400;
  font-size: 16px;
  color: #25111a;
  border: solid 2px #d7d7d7 !important;
  
  -webkit-border-radius: 7px;
  border-radius: 7px;
}
.d_form_buttons>.btn{
  margin-right: 10px;

  -webkit-box-shadow: 0 0 0 0 rgba(0,0,0,0);
  box-shadow: 0 0 0 0 rgba(0,0,0,0);
}
.d_form_buttons>.btn:last-child{
  margin-right: 0px;
  -webkit-box-shadow: -8px 3px 8px 1px rgba(0,0,0,0.3);
  box-shadow: -8px 3px 8px 1px rgba(0,0,0,0.3);
}
.d_number_suffix{
  position: relative;
}
.d_number_suffix .right_controls{
  display: flex;
  align-items: center;
  justify-content: flex-end;

  position: absolute;
  right: 10px;
  top: 0px;
  height: 100%;
}
.d_number_suffix .right_controls .label{
  margin-left: 15px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 5px 8px;
  background-color: #8421c3;

  -webkit-border-radius: 7px;
  border-radius: 7px;
}
.right_controls .arrows img{
  width: 23px;
  height: 23px;
  display: block;
}
.dashboard_sidebar_menu li a.account_link>img{
  width: 38px;
  height: 38px;
  margin-right: 5px;
}
.dashboard_sidebar_menu li a.account_link span{
  display: block;
}
.dashboard_sidebar_menu li a.account_link strong{
  display: block;
}
.dashboard_sidebar_menu li a.account_link{
  display: flex;
  align-items: center;
  line-height: 1;
}


.dashboard_sidebar_menu ul.logged_user{
  background-color: #d0c7b6;
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  top: auto;
  height: auto;

  padding-top: 20px;
  padding-bottom: 20px;
}
.dashboard_sidebar_menu ul.logged_user li a{
  font-weight: 400;
}
.dashboard_sidebar_menu ul.logged_user li{
  margin-bottom: 15px;
}

.d_illustration_signup{
  object-position: center;
  -o-object-position: center;
  width: 1433px !important;
}

.signup_form{
  width: 975px;
  height: 100vh;
  background-color: #fcf5e9;
  overflow: auto;

  padding: 40px;

  position: absolute;
  right: 0px;
  top: 0px;
}
.signup_form_inner_content{
  width: 485px;
  margin-left: auto;
  margin-right: auto;
}

.z_checkbox{
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;

  font-size: 18px;
  color: #25111a;
  font-weight: 400;
}
.z_checkbox>*{
  display: block;
}
.z_checkbox input{
  display: none;
}
.z_checkbox>.box{
  width: 25px;
  height: 25px;
  margin-right: 20px;
  background-color: white;

  -webkit-border-radius: 5px;
  border-radius: 5px;

  position: relative;
}
.z_checkbox.checked>.box:after{
  display: block;
  content: '';

  width: 20px;
  height: 20px;
  position: absolute;
  left: calc(50% - 10px);
  top: calc(50% - 10px);

  -webkit-border-radius: 5px;
  border-radius: 5px;

  background-color: #00827a;
}

.signup_logo{
  display: block;
  text-decoration: none;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 35px;
  width: 100%;
  max-width: 201px;
}
.signup_logo img{
  display: block;
  width: 100%;
  height: auto;
}

.signup_form_intro_content{
  text-align: center;
  margin-bottom: 30px;
}
.signup_form_intro_content h1{
  font-size: 24px;
  font-weight: bold;
  color: #25111a;
  margin-bottom: 10px;
}
.signup_form_intro_content p{
  font-size: 18px;
  color: #25111a;
  margin-bottom: 10px;
}
.signup_form_intro_content p a{
  color: #00827a;
  text-decoration: none;
}
.signup_form_intro_content p a:hover{
  text-decoration: underline;
}
.signup_form_group{
  margin-bottom: 20px;
}
.signup_form_group input{
  font-size: 18px;
  font-weight: 400;
  color: #25111a;
  line-height: 1;

  padding: 29px 34px;
}
.signup_checkboxes{
  padding-top: 10px;
  margin-bottom: 25px;
}
.signup_recaptcha{
  margin-bottom: 30px;
}
.signup_recaptcha>img{
  display: block;
  width: 373px;
  height: 96px;
  object-fit: contain;
  object-position: left center;
  -o-object-fit: contain;
  -o-object-position: left center;
}
.signup_buttons_holder{
  text-align: center;
}
.signup_buttons_holder>.btn{
  min-width: 300px;
}


.wallet_balances{
  padding-top: 15px;
}
.wallet_balances h4{
  font-weight: 400;
  font-size: 18px;
  color: #25111a;
  margin-bottom: 25px;
}
.wallet_balance_item{
  border: solid 2px #d7d7d7;
  background-color: white;
  margin-bottom: 13px;

  padding: 12px 18px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  -webkit-border-radius: 7px;
  border-radius: 7px;
}
.wallet_balance_item.warning{
  border-width: 3px;
  border-color: #ed1c24;
}
.wallet_balance_item .warning_content{
  font-size: 18px;
  font-weight: 400;
  color: #25111a;
  padding-left: 37px;
  position: relative;
}
.wallet_balance_item .warning_content:before{
  width: 26px;
  height: 26px;
  background-image: url('../Images/icon-warning-red.png');
  background-size: contain;
  background-position: center;
  display: block;
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
}
.wallet_balance_item .right_text{
  font-size: 18px;
  font-weight: 400;
  color: #25111a;

  padding: 4px 0px;
  padding-right: 45px;

  background-image: url('../Images/icon-round-arrow-right-black.png');
  background-size: 33px 33px;
  background-position: right center;
  background-repeat: no-repeat;
}
.wallet_balance_item .right_text p{
  margin-bottom: 5px;
  line-height: 1;
}
.wallet_balance_item .coin_content{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.wallet_balance_item .coin_content strong{
  font-size: 18px;
  font-weight: bold;
  color: #25111a;
}
.wallet_balance_item .coin_content>img{
  display: block;
  width: 44px;
  height: 44px;
  object-fit: contain;
  object-position: center;
  -o-object-fit: contain;
  -o-object-position: center;
  margin-right: 20px;
}

section.photo>img{
  width: 100% !important;
  height: auto !important;
}
.photo.photo_above_newsletter img{
  object-fit: cover;
  object-position: center;
  -o-object-fit: cover;
  -o-object-position: center;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.photo.photo_above_newsletter{
  overflow: hidden;
  cursor: pointer;
}
/*.photo.photo_above_newsletter:hover img{
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -o-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
}*/


.newsletter_content .input-group{
  margin-bottom: 1vw;
}
.newsletter_content form button:not(.dropdown-toggle){
  min-width: 400px;
}

.PhoneInputInput{
  border: none;
  outline: none;
}



.tooltip-inner{
  background-color: #331220;
  background-color: #fcf5e9;
  color: #fcf5e9;
  color: #e53634;
  max-width: 400px;
  padding: 15px 30px;
  font-weight: 900;
  font-size: 20px;

  border: solid 5px #fcf5e9;

  opacity: 1 !important;

  -webkit-border-radius: 10px;
  border-radius: 10px;

  position: relative;
}
.tooltip.show{
  opacity: 1;
  margin-bottom: 10px !important;
}
.tooltip{
  margin-bottom: 10px !important;
}
.bs-tooltip-top .tooltip-arrow:before{
  border-top-color: #fcf5e9;
  border-width: 10px;
}