/*Based on size 375px width*/
header.is_for_mobile{
  
}
@media (max-width: 800px){


  body {
    --grid_padding:calc(100vw *15 / 375);
    font-size: calc(100vw * 16 /375);
    
  }

  .big_header_with_content.the_green_planet .rockets_holder{
    height: calc(100vw * 320 / 375);
    background-position: left top;
    top: calc(100vw * 330 / 375);
  }


  /*bootstrap start*/
  .btn {
    border: solid calc(100vw *5 / 375) #f2ecd1;
    border-radius: calc(100vw *5 / 375);
    font-size: calc(100vw *18 / 375);
    padding: calc(100vw *12 / 375) calc(100vw *20 / 375);
    text-shadow: calc(100vw *1 / 375) calc(100vw *1 / 375) calc(100vw *4 / 375) #000000;
    box-shadow: 0 calc(100vw *10 / 375) calc(100vw *10 / 375) calc(100vw *1 / 375) rgba(0,0,0,0.3);
  }
  .form-control {
    border-radius: calc(100vw *5 / 375);
  }
  .form-control {
    padding: calc(100vw *10 / 375) calc(100vw *20 / 375);
    font-size: calc(100vw *22 / 375);
  }
  .input-group{
    
  }
  .input-group>*{
    
    margin: 0px !important;
  }
  .input-group>*:first-child{
    -webkit-border-radius: calc(100vw *5 / 375) calc(100vw *5 / 375) 0 0 !important;
    border-radius: calc(100vw *5 / 375) calc(100vw *5 / 375) 0 0 !important;
  }
  .input-group>*:last-child{
    -webkit-border-radius: 0 0 calc(100vw *5 / 375) calc(100vw *5 / 375) !important;
    border-radius: 0 0 calc(100vw *5 / 375) calc(100vw *5 / 375) !important;
  }
  .input-group>*:last-child,
  .input-group>.btn{
    border-radius: calc(100vw *5 / 375) !important;
  }
  /*bootstrap end*/





  header{
    top: calc(100vw *200 / 375);
  }
  header .btn_header{
    min-width: calc(100vw * 250 / 375);
  }
  header.is_for_mobile{
    
    top: 0px;
  }
  .header_left_menu_set{

  }
  .header_main_logo img{
    height: calc(100vw *40 / 375);
  }
  header.is_for_mobile .header_main_logo{
    

  }
  .hamburg_button{

  }
  body{
    --hamburg_side_space: calc(100vw *5 / 375);
    --hamburg_tb_space: calc(100vw *5 / 375);
    --hamburg_line_height: calc(100vw *3 / 375);
    --hamburg_line_height: calc(100vw *3 / 375);
    --hamburg_width: calc(100vw *50 / 375);
    --hamburg_height: calc(100vw *40 / 375);
  }
  .hamburg_button{
    
  
  }
  .hamburg_button>span{
    
  }
  .hamburg_button>span:nth-child(1){
    
  }
  .hamburg_button>span:nth-child(2),
  .hamburg_button>span:nth-child(3){
    
  }
  .hamburg_button>span:nth-child(4){
    
  }
  .hamburg_button.clicked>span:nth-child(1){
    
  }
  .hamburg_button>span:nth-child(2),
  .hamburg_button>span:nth-child(3){
    
  }
  .hamburg_button.clicked>span:nth-child(2){
    
  }
  .hamburg_button.clicked>span:nth-child(3){
    
  }
  .hamburg_button.clicked>span:nth-child(4){
    
  }
  .hamburg_button.clicked{
    
  }
  header:not(.is_for_mobile) .header_main_logo{
  
  }
  header{
    
    padding: calc(100vw *10 / 375) 0px;
  }
  header:not(.is_for_mobile){
    top: calc(100vw *63 / 375);
    height: calc(100vh - calc(100vw 63 / 375));
    

    padding-top: calc(100vw *30 / 375);
    padding-bottom: calc(100vw *30 / 375);

  
  }
  header:not(.is_for_mobile) .col-lg-12{
    
  }
  header:not(.is_for_mobile) .col-lg-12 ul{
    
    max-width: calc(100vw *320 / 375);
  }
  .header_left_menu>li{
    margin-right: 0px;
    
  }
  .header_menu_user>li{
    margin-right: 0px;
    
  }
  .btn_icon_header{
    padding: calc(100vw *15 / 375) 0px;
  }
  header.make_header_for_active_hamburg{
  
  }
  header:not(.is_for_mobile){
    
  }
  body.menu_is_visible header:not(.is_for_mobile){
    
  }
  .header_menu_user li{
    margin-bottom: calc(100vw *15 / 375);
  }
  body.menu_is_visible{
    
  }
  header{
    border-width: calc(100vw *3 / 375);
  }
  .btn_icon_header,
  .header_left_menu a{
    font-size: calc(100vw *18 / 375);
    padding: calc(100vw *5 / 375) 0px;
  }
  .btn_icon_header img{
    vertical-align: calc(100vw *-3 / 375);
    height: calc(100vw *18 / 375);
    margin-right: calc(100vw *7 / 375);
  }



  .big_header_with_banner .container{
    


  }
  /*.big_header_with_banner .content{
    max-width: calc(100vw *300 / 375);
    max-width: calc(100vw *235 / 375);
  }*/

  .big_header_with_banner .content>h1{
    font-size: calc(100vw * 50 / 375);
    margin-bottom:  calc(100vw * 20 / 375);
    text-shadow: 0px  calc(100vw * 3 / 375) 0px #f06230;
  }
  .big_header_with_banner .content>h1>*{
  }
  .big_header_with_banner .content>h1>strong{
    margin-bottom:  calc(100vw * 3 / 375) !important;
    text-shadow: 0px  calc(100vw * 3 / 375) 0px #fab132;
    
  }
  .big_header_with_banner .content>h1>i{
    margin-bottom:  calc(100vw * 7 / 375);
    text-shadow: 0px  calc(100vw * 3 / 375) 0px transparent;
  }


  .big_header_with_banner{
    min-height: calc(100vw *330 / 375);
    padding: calc(100vw *140 / 375) 0px;

  }


  .liberty_banner_content>div{
    margin-bottom: calc(100vw *20 / 375);
  }
  .liberty_banner .liberty_banner_content h1, .liberty_banner .liberty_banner_content h2, .liberty_banner .liberty_banner_content h3 {
    font-size: calc(100vw *35 / 375);
    letter-spacing: calc(100vw *-3 / 375);
    letter-spacing: 0px;
    text-shadow: 0px calc(100vw *3 / 375) 0px #bdb785;
    margin-bottom: calc(100vw *20 / 375);
  }
  .liberty_banner .liberty_banner_content {
    border-radius: calc(100vw *30 / 375);
    
    padding-top: calc(100vw *35 / 375);
    padding-bottom: calc(100vw *30 / 375);
  }
  .liberty_banner{
    
    padding-top: calc(100vw *50 / 375);
    padding-bottom: calc(100vw *70 / 375);
  }


  .content_photo{
    padding: calc(100vw *50 / 375) 0px;
  }
  .content_photo:before {
    right: 0px;
    top: 0px;
    border: solid calc(100vw *5 / 375) #fcf5e9;

    border-radius: 0px 0 0 0px;
    
  }
  .content_photo.padding-bottom-for-x3-boxes{
    padding: calc(100vw *50 / 375) 0px;
  }
  .content_photo .inner_content h2, .content_photo .inner_content h3 {
    font-size: calc(100vw *35 / 375);
    letter-spacing: 0px;
    margin-bottom: calc(100vw *20 / 375);
    text-shadow: 0px calc(100vw *4 / 375) 0px #ac4a39;
  }
  .content_photo:after{
    height: calc(100vw *150 / 375);
    
  }


  .x3_yellow_boxes.margin-top-go-up{
    margin-top: calc(100vw *50/375);
  }
  .x3_yellow_boxes{
    margin-bottom: calc(100vw *50/375);
    padding-bottom: 0px;
  }
  .list_yellow_boxes{
    margin-bottom: calc(100vw *35 / 375);

  }
  .list_yellow_boxes li>span.logo{
    margin-bottom: calc(100vw *30 / 375);
  }
  .list_yellow_boxes li>span.logo .logo_background, .list_yellow_boxes li>span.logo:before {
    width: calc(100vw *120 / 375);
    height: calc(100vw *120 / 375);
    left: calc(50% - calc(100vw *120 / 375)/2);
    top: calc(50% - calc(100vw *120 / 375)/2);
  }
  .list_yellow_boxes li>span.logo:after {
    width: calc(100vw *140 / 375);
    height: calc(100vw *140 / 375);
    left: calc(50% - calc(100vw *140 / 375)/2);
    top: calc(50% - calc(100vw *140 / 375)/2);
  }
  .list_yellow_boxes li>span.logo>img {
    width: calc(100vw *80 / 375);
    height: calc(100vw *80 / 375);
  }
  .list_yellow_boxes li {
    font-size: calc(100vw *18 / 375);
    border: solid calc(100vw *5 / 375) #fff9ef;
    padding: calc(100vw *20 / 375) var(--grid_padding);
    padding-top: calc(100vw *30 / 375);
    padding-bottom: calc(100vw *25 / 375);
    border-radius: calc(100vw *40 / 375);
    box-shadow: 0 calc(100vw *5 / 375) 0 calc(100vw *1 / 375) #d2cbc2;
    margin-bottom: calc(100vw *40 / 375);
  }
  .list_yellow_boxes h4 {
    margin-bottom: calc(100vw *15 / 375);
    font-size: calc(100vw *22 / 375);
    text-shadow: 0px calc(100vw *3 / 375) 0px #ac4a39;
  }


  .content_centered{
    padding-top: calc(100vw *50 / 375);
    padding-bottom: calc(100vw *50 / 375);
  }
  .content_centered:before {
    left: 0px;
    top: 0px;
    
    border: solid calc(100vw *5 / 375) #fcf5e9;
    border-radius: 0 0px 0px 0;
    
  }
  .content_centered .content h5 {
    font-size: calc(100vw *16 / 375);
    letter-spacing: calc(100vw *6 / 375);
    margin-bottom: calc(100vw *17 / 375);
  }
  .content_centered .content h2, .content_centered .content h3 {
    font-size: calc(100vw *40 / 375);
    letter-spacing: 0px;
    text-shadow: 0px calc(100vw *3 / 375) 0px #ac4a39;
  }
  .content_centered .content{
    margin-bottom: calc(100vw *40 / 375);
  }
  .list_box_posts li{
    padding-top: calc(100vw *300 / 375);
  }
  .list_box_posts li span.date {
    font-size: calc(100vw *14 / 375);
    margin-bottom: calc(100vw *10 / 375);
  }


  .logos_box{
    padding-top: calc(100vw *50 / 375);
  }
  .logox_box_content {
    padding: calc(100vw *35 / 375) var(--grid_padding);
    padding-bottom: calc(100vw *50 / 375);
    border-radius: calc(100vw *40 / 375);
  }
  .logos_box .content h2, .logos_box .content h3 {
    margin-bottom: calc(100vw *20 / 375);
    font-size: calc(100vw *25 / 375);
    letter-spacing: 0px;
    text-shadow: calc(100vw *2 / 375) calc(100vw *2 / 375) 0px #fcddaf;
  }
  .logos_box .content p{
    font-size: calc(100vw *18 / 375);
  }
  .logox_box_content .the_logos img {
    height: calc( 100vw *40 / 375);
    max-width: calc(100vw *150 / 375);
    margin: calc(100vw *20 / 375) calc(100vw *10 / 375) !important;
  }

  .newsletter .banner_holder img{
  
    height: calc(100vw *170 / 375);
  
    left: calc(100vw *-110 / 375);
    left: 0px;

    
  }
  .newsletter{
    
    margin-bottom: calc(100vw *50 / 375);

    padding-top: calc(100vw *60 / 375);
    padding-bottom: calc(100vw *80 / 375);
  }
  .newsletter_content{
    max-width: calc(100vw *270 / 375);

  }
  .newsletter .html_content h5 {
    font-size: calc(100vw *18 / 375);
    margin-bottom: calc(100vw *15 / 375);
    letter-spacing: calc(100vw *6 / 375);
  }
  .newsletter .html_content h2, .newsletter .html_content h3 {
    font-size: calc(100vw *40 / 375);
    letter-spacing: 0px;
    text-shadow: 0px calc(100vw *3 / 375) 0px #ac4a39;
    margin-bottom: calc(100vw *15 / 375);

  }
  .newsletter .html_content p{
    font-size: calc(100vw *18 / 375);
    margin-bottom: calc(100vw *15 / 375);
  }
  .newsletter .html_content{
    margin-bottom: calc(100vw *20 / 375);
  }
  .newsletter_content{
  
  }
  .newsletter .html_content h2, .newsletter .html_content h3{
    font-size: calc(100vw *33 / 375);
  }
  .newsletter .container{
    
  }


  footer{
    padding: calc(100vw *50 / 375) 0px;
  }
  .footer_main_link img{
    
  }
  .footer_main_link{
    margin-bottom: calc(100vw *30 / 375);
    width: calc(100vw *200 / 375);
  }
  footer .col-lg-12{
    
  }
  .footer_social_icons{
    
  }
  .footer_social_icons a>img{
    width: calc(100vw *40 / 375);
    height: calc(100vw *40 / 375);
  }
  .footer_social_icons>li{
    margin: 0px calc(100vw *5 / 375);
  }


  .big_header_with_content .the_planet{
    top: calc(100vw *70 / 375);
    height: calc(100vw *100 / 375);
  }
  .big_header_with_content{
    min-height: 0px;
    padding-top: calc(100vw *150 / 375);
    padding-bottom: calc(100vw *120 / 375);
    
  }
  .big_header_with_content .content h1, .big_header_with_content .content h2 {
    font-size: calc(100vw *40 / 375);
    text-shadow: calc(100vw *3 / 375) calc(100vw *3 / 375) 0px #784D2E;
    margin-bottom: calc(100vw *20 / 375);
  }
  .big_header_with_content.is_for_post .content h1, .big_header_with_content.is_for_post .content h2{
    font-size: calc(100vw *32 / 375);
  }
  .big_header_with_content .content{
  
  }
  .big_header_with_content .rockets_holder .rockets{
    
    top: calc(100vw *150 / 375);
    top: 0px;
    background-size: auto calc(100vw *420 / 375);
  }
  .big_header_with_content.the_people_look .rockets_holder .rockets{
  
  }
  .big_header_with_content .rockets_holder:after{
    
  }
  .big_header_with_content .stars{
  
  }
  .big_header_with_content .rockets_holder{
    
    top: 0px;
  }
  .big_header_with_content .content p{
    font-size: calc(100vw * 18 / 375);
  }

  .content_tv_circle.title_smaller h2{
    font-size: calc(100vw *35 / 375);
  }
  .content_tv_circle h2 {
    font-size: calc(100vw *40 / 375);
    margin-bottom: calc(100vw *20 / 375);
    text-shadow: 0px calc(100vw *3 / 375) 0px #78482b;
    letter-spacing: 0px;
  }
  .content_tv_circle{
    
  }
  .content_tv_circle .circle_content.just_tv {
    padding: calc(100vw *25 / 375) 0px;
    

  }

  .content_tv_circle .the_tv:before {
    
    width: calc(100vw *375 / 375);
    height: calc(100vw *320 / 375);

    
  }
  .content_tv_circle .circle_content{
    background-size: 100% calc(100% + (100vw *100 / 375));
  }
  .content_tv_circle .the_tv{
    width: calc(100vw *250 / 375);
  }

  .content_photo .content_top {
    margin-bottom: calc(100vw *35 / 375);
  }
  .content_photo .content_top h2, .content_photo .content_top h3 {
    margin-bottom: calc(100vw *20 / 375);
    font-size: calc(100vw *32 / 375);
    text-shadow: 0px calc(100vw *3 / 375) 0px #ac4a39;
  }
  .icons_content li{
    
    margin-bottom: calc(100vw *50 / 375);
  }
  .icons_content li>*{
    
  }
  .icons_content li .image {
    width: calc(100vw *130 / 375);
    height: calc(100vw *130 / 375);
    margin-bottom: calc(100vw *5 / 375);
    
  }
  .icons_content li p {
    margin-bottom: calc(100vw *20 / 375);
    font-size: calc(100vw *18 / 375);
  }
  .icons_content li .btn {
    min-width: calc(100vw *250 / 375);
  }
  .icons_content li .content{

  }
  .icons_content li .image img {
    width: calc(100vw *60 / 375);
    height: calc(100vw *60 / 375);
    border-radius: calc(100vw *30 / 375);
    box-shadow: 0 calc(100vw *5 / 375) 0 calc(100vw *1 / 375) transparent;
  }

  .content_centered_2 {
    padding-top: calc(100vw *70 / 375);
    padding-bottom: calc(100vw *50 / 375);
  }
  .content_centered_2 .left_circle {
    height: calc(100vw *100 / 375);
    top: calc(100vw *100 / 375);
  }
  .content_centered_2 .right_circle{
    height: calc(100vw *140 / 375);
    top: calc(100vw *50 / 375);
  }
  .content_centered_2 h1, .content_centered_2 h2, .content_centered_2 h3 {
    font-size: calc(100vw *40 / 375);
    margin-bottom: calc(100vw *30 / 375);
    text-shadow: 0px calc(100vw *3 / 375) 0px #78482b;
    letter-spacing: 0px;
  }
  .content_centered_2 p {
    font-size: calc(100vw *18 / 375);
    margin-bottom: calc(100vw *25 / 375);
  }
  .buttons_holder>*{
    min-width: calc(100vw *250 / 375);
    margin-bottom: calc(100vw *10 / 375);
  }

  .big_header_with_content .btn{
    min-width: calc(100vw *250 / 375);
  }
  .big_header_with_content.add_margin{
    margin-bottom: 0px;
  }

  .content_box_item {
    border: solid calc(100vw *5 / 375) #fff9ef;
    border-radius: calc(100vw *40 / 375);
    margin-bottom: calc(100vw *70 / 375);
    padding: calc(100vw *70 / 375) var(--grid_padding);
    min-height: 0px;
  }
  .content_box_item h2, .content_box_item h3 {
    font-size: calc(100vw *32 / 375);
    margin-bottom: calc(100vw *35 / 375);
    letter-spacing: 0px;
    text-shadow: 0px calc(100vw *3 / 375) 0px #ac4a39;
  }
  .content_box_item p{
    font-size: calc(100vw *22 / 375);
  }
  .content_box_item>div{
    
  }
  .content_photo.planets-and-stars:before{
    
  }































  /*Responsivity 1920 with 375*/
  .login_form_inputs {
    max-width: calc(100vw *600 / 375);
    padding-bottom: calc(100vw *25 / 375);
    border-radius: calc(100vw *20 / 375);
  }
  .login_form_ufo{
    max-width: calc(100vw *220 / 375);
    margin-bottom: calc(100vw *30 / 375);
  }
  .login_form_inputs .login_logo{
    width: calc(100vw *150 / 375);
    margin-bottom: calc(100vw *20 / 375);
  }
  .login_form_control_holder .form-control {
    border-radius: calc(100vw *5 / 375);
    font-size: calc(100vw *16 / 375);
    padding: calc(100vw *15 / 375) calc(100vw *18 / 375);
  }
  .login_form_control_holder.have_icon .form-control{
    padding-right: calc((100vw *30 / 375) + (100vw *10 / 375));
  }
  .login_form_control_holder.have_icon>img {
    height: calc(100vw *20 / 375);
    left: calc(100% - (100vw *20 / 375) - calc(100vw *10 / 375));
    top: calc(50% - (100vw *20 / 375)/2);
  }
  .login_form_control_holder{
    margin-bottom: calc(100vw *20 / 375);
  }
  .login_big_button_holder>.btn {
    min-width: calc(100vw *150 /375);
    -webkit-box-shadow: calc(100vw *-5 / 375) calc(100vw *5 / 375) calc(100vw *4 / 375) calc(100vw *1 / 375) rgba(0,0,0,0.2);
    box-shadow: calc(100vw *-5 / 375) calc(100vw *5 / 375) calc(100vw *4 / 375) calc(100vw *1 / 375) rgba(0,0,0,0.2);
  }
  .login_big_button_holder {
    padding-top: calc(100vw *10 / 375);
    margin-bottom: calc(100vw *20 / 375);
  }
  .login_forgot_link{
    margin-bottom: calc(100vw * 40 / 375);
  }


  .dashboard_sidebar_menu{
    width: calc(100vw *270 / 375);
  }
  .dashboard_sidebar_menu .d_logo {
    width: calc(100vw *113 / 375);
    padding-top: calc(100vw *25 / 375);
    padding-bottom: calc(100vw *30 / 375);
  }
  .dashboard_sidebar_menu a {
    font-size: calc(100vw *18 / 375);
    padding-right: calc(100vw *15 / 375);
    padding-top: calc(100vw 810 / 375);
    padding-bottom: calc(100vw *10 / 375);
  }
  .dashboard_sidebar_menu>ul>li>a {
    padding-left: calc(100vw *15 / 375);
    padding-right: calc(100vw *15 / 375);
  }
  .dashboard_sidebar_menu li a>img {
    width: calc(100vw *24 / 375);
    height: calc(100vw *22 / 375);
    margin-right: calc(100vw *15 / 375);
    vertical-align: calc(100vw *-3 / 375);
  }
  .dashboard_sidebar_menu li a.account_link>img {
    width: calc(100vw *38 / 375);
    height: calc(100vw *38 / 375);
    margin-right: calc(100vw *5 / 375);
  }
  .dashboard_sidebar_menu .d_logo{
    padding-right: 0px;
  }
  .dashboard_sidebar_menu .d_logo img {
    height: calc(100vw *27 / 375);
  }
  .dashboard_sidebar_menu>ul>li>.submenu a {
    padding: calc(100vw *10 / 375) 0px;
    padding-left: calc(100vw *37 / 375);
    padding-left: calc(100vw *52 / 375);
  }
  .dashboard_sidebar_menu>ul {
    height: calc(100vh - (100vw *135 / 375) - (100vw *80 / 375));
  }

  .dashboard_sidebar_menu ul.logged_user {
    left: 0px;
    bottom: 0px;
    padding-top: calc(100vw *20 / 375);
    padding-bottom: calc(100vw *20 / 375);
  }
  .dashboard_sidebar_menu ul.logged_user li {
    margin-bottom: calc(100vw *15 / 375);
  }
  .dashboard_sidebar_menu>ul {
    height: calc(100vh - (100vw *135 / 375) - (100vw *110 / 375));
  }
  .dashboard_sidebar_menu ul.logged_user li{
    margin-bottom: calc(100vw *5 / 375);
  }
  .dashboard_sidebar_menu>ul>li.opened {
    margin-bottom: calc(100vw *15 / 375)  
  }
  .dashboard_sidebar_menu>ul>li.opened>.submenu {
    padding-bottom: calc(100vw *10 / 375);
    padding-bottom: calc(100vw *20 / 375);
  }
  .dashboard_sidebar_menu{
    left: calc(100vw *-270 / 375);
  }
  body.d_sidebar_is_visible .dashboard_sidebar_menu{
    left: 0px;
  }
  .dashboard_sidebar_menu{
  }



  .dashboard_counts_grid .col-lg-12{
  }
  .dashboard_panel_count{
    margin-bottom: calc(100vw *20 / 375);
  }
  .dashboard_content_area {
    padding: calc(100vw *95 / 375) calc(100vw *80 / 375);
    padding-right: calc(100vw *120 / 375);
    padding: calc(100vw *30 / 375) calc(100vw *20 / 375);
    width: calc(100% - calc(100vw *270 / 375));
    margin-left: calc(100vw *270 / 375);
    margin-left: 0px;
  }
  .dashboard_content_area{
    padding-left: 0px;
    padding-right: 0px;
  }

  .dashboard_panel_count h4{
    font-size: calc(100vw *20 / 375);
  }
  .dashboard_panel_count .count{
    padding: calc(100vw *40 / 375);
    font-size: calc(100vw *50 / 375);
  }
  .dashboard_panel_count .count small{
    font-size: calc(100vw *16 / 375);
  }
  .dashboard_panels_x2_areas .area_1{
    min-height: calc(100vw *95 / 375);
  }
  .dashboard_panels_x2_areas>div {
    margin-bottom: calc(100vw *20 / 375);
    border-radius: calc(100vw *15 / 375);
  }
  .dashboard_panels_x2_areas .area_2{
    min-height: calc(100vw *170 / 375);
  }
  .dashboard_content_area .container {
    margin-bottom: calc(100vw *20 / 375);
  }

  .dashboard_content_panel {
    padding: calc(100vw *20 / 375) calc(100vw *30 / 375);
    padding: calc(100vw *20 / 375) calc(100vw *20 / 375);
    padding-bottom: calc(100vw *30 / 375);
    min-height: 0px !important;
  }
  .dashboard_panel_count {
    border-radius: calc(100vw *20 / 375);
    padding: calc(100vw *25 / 375) calc(100vw *35 / 375);
  }
  .dashboard_content_panel {
    border-radius: calc(100vw *15 / 375);
  }
  .dashboard{
    padding-top: calc((100vw *63 / 375) + (100vw *10 / 375));
  }
  .dashboard_content_area{
    padding-top: 0px;
  }
  .dashboard_sidebar_menu>ul>li.opened>.submenu a.active:before, .dashboard_sidebar_menu>ul>li>a.active::before{
    width: calc(100vw *6 / 375);
  }

  .dashboard_panel_count .d_icon_info {
    top: calc(100vw *10 / 375);
    right: calc(100vw *10 / 375);
  }
  .d_icon_info {
    width: calc(100vw *20 / 375);
    height: calc(100vw *20 / 375);
  }
  .dashboard_panel_count a {
    bottom: calc(100vw *10 / 375);
    right: calc(100vw *25 / 375);
    right: calc(100vw *10 / 375);
    max-width: calc(100% - 2(100vw *10 / 375));
    font-size: calc(100vw *16 / 375);
  }




  .wallet_widget_header {
    margin-bottom: calc(100vw *20 / 375);
    padding: calc(100vw *15 / 375) calc(100vw *25 / 375);
    border-radius: calc(100vw *20 / 375);
  }
  .wallet_widget{
  }
  .wallet_widget_header h1 {
    font-size: calc(100vw *20 / 375);
    margin-bottom: calc(100vw *20 / 375);
  }
  .wallet_widget_header h1 img {
    margin-right: calc(100vw *10 / 375);
    width: calc(100vw *40 / 375);
    height: calc(100vw *40 / 375);
  }
  .wallet_widget_header ul{
  }
  .wallet_widget_header ul li a {
    font-size: calc(100vw *18 / 375);
    padding-bottom: calc(100vw *10 / 375);
  }
  .wallet_widget_header ul li a:after {
    height: calc(100vw *5 / 375);
  }
  .btn_walled_send_receive {
    font-size: calc(100vw *18 / 375);
    padding: calc(100vw *10 / 375) calc(100vw *20 / 375);
  }
  .btn_walled_send_receive:before {
    width: calc(100vw *13 / 375);
    height: calc(100vw *22 / 375);
    margin-right: calc(100vw *10 / 375);
  }
  .walled_widget_actions .content{
    padding: calc(100vw *20 / 375);
  }
  .walled_widget_actions .content h3 {
    margin-bottom: calc(100vw *20 / 375);
    font-size: calc(100vw *18 / 375);
  }
  .item_price h5 {
    font-size: calc(100vw *16 / 375);
    margin-bottom: calc(100vw *5 / 375);
  }
  .item_price p {
    padding-left: calc(100vw *20 / 375);
  }
  .item_prices_holder .item_price{
    margin-bottom: calc(100vw *20 / 375);
  }
  .item_price{
    font-size: calc(100vw *16 / 375);
  }
  .item_price_info {
    margin-left: calc(100vw *5 / 375);
    vertical-align: calc(100vw *-4 / 375);
  }
  .item_price_info img {
    width: calc(100vw *17 / 375);
  }

  .d_group{
    margin-bottom: calc(100vw *20 / 375);
  }
  .d_group label {
    margin-bottom: calc(100vw *5 / 375);
    font-size: calc(100vw *16 / 375);
  }
  .d_group input {
    padding: calc(100vw *12 / 375) calc(100vw *14 / 375);
    font-size: calc(100vw *16 / 375);
    border-width: calc(100vw *2 / 375);
    border-radius: calc(100vw *7 / 375);
  }
  .d_number_suffix .right_controls {
    right: calc(100vw *10 / 375);
    top: 0px;
  }
  .right_controls .arrows img {
    width: calc(100vw *20 / 375);
    height: calc(100vw *20 / 375);
  }
  .d_number_suffix .right_controls .label {
    margin-left: calc(100vw *10 / 375);
    font-size: calc(100vw *16 / 375);
    padding: calc(100vw *5 / 375) calc(100vw *8 / 375);
    border-radius: calc(100vw *5 / 375);
  }

  .btn.btn-sm {
    font-size: calc(100vw *18 / 375);
    padding: calc(100vw *14 / 375) calc(100vw *35 / 375);
  }
  .d_form_buttons>*{
    margin: 0px;
    margin-bottom: calc(100vw *10 / 375);
  }

  .dashboard_content_panel h1 {
    font-size: calc(100vw *22 / 375);
    margin-bottom: calc(100vw *15 / 375);
  }
  .wallet_balances h4 {
    font-size: calc(100vw *18 / 375);
    margin-bottom: calc(100vw *20 / 375);
  }
  .wallet_balance_item.warning {

    /*Don't change this border-width*/
    border-width: 2px;
  }
  .wallet_balance_item {
    margin-bottom: calc(100vw *10 / 375);
    padding: calc(100vw *12 / 375) calc(100vw *18 / 375);
    border-radius: calc(100vw *7 / 375);
  }
  .wallet_balance_item .warning_content {
    font-size: calc(100vw *16 / 375);
    padding-left: calc(100vw *25 / 375);
  }
  .wallet_balance_item .warning_content:before {
    width: calc(100vw *15 / 375);
    height: calc(100vw *15 / 375);
    left: 0px;
    top: calc(100vw *2 / 375);
  }

  .wallet_balance_item .right_text {
    font-size: calc(100vw *16 / 375);
    padding: calc(100vw *4 / 375) 0px;
    padding-right: calc(100vw *25 / 375);
    background-size: calc(100vw *22 / 375) calc(100vw *22 / 375);
  }
  .wallet_balance_item{
  }
  .wallet_balance_item>*{
    margin-bottom: calc(100vw *10 / 375);
  }
  .wallet_balance_item .coin_content strong{
    font-size: calc(100vw *16 / 375);
  }
  .wallet_balance_item .right_text p{
    margin-bottom: calc(100vw *5 / 375);
  }
  .wallet_balance_item .coin_content>img {
    width: calc(100vw *25 / 375);
    height: calc(100vw *25 / 375);
    margin-right: calc(100vw *20 / 375);
  }
  .wallet_balance_item .coin_content>img{
    margin-right: calc(100vw *7 / 375);
  }
  .d_form_buttons>.btn:last-child {
    margin-right: 0px;
    box-shadow: calc(100vw *-5 / 375) calc(100vw *3 / 375) calc(100vw *3 / 375) calc(100vw *1 / 375) rgba(0,0,0,0.5);
  }
  .dashboard_content_panel h1{
    font-size: calc(100vw *20 / 375);
  }
  .d_infoParagraph {
    font-size: calc(100vw *16 / 375);
    padding: calc(100vw *5 / 375) 0px;
    padding-left: calc(100vw *25 / 375);
    background-size: calc(100vw *17 / 375) calc(100vw *17 / 375);
    margin-bottom: calc(100vw *15 / 375);
  }
  .d_copy_text {
    font-size: calc(100vw *16 / 375);
  }
  .d_copy_text span {
    margin-left: 0px;
    font-size: calc(100vw *12 / 375);
    padding: calc(100vw *15 / 375) calc(100vw *10/375);
    padding-right: calc(100vw *50 / 375);
    background-size: calc(100vw *16 / 375) auto;
    background-position: calc(100% - (100vw *15 / 375)) center;
    border-radius: calc(100vw *5 / 375);
    /*Dont change this border*/
    border-width: 2px;
  }

  .d_hr {
    height: calc(100vw *2 / 375);
    margin: calc(100vw *20 / 375) 0px;
  }
  .d_centeredTexts h5 {
    font-size: calc(100vw *16 / 375);
    margin-bottom: calc(100vw *12 / 375);
  }
  .d_centeredTexts p {
    font-size: calc(100vw *16 / 375);
    margin-bottom: calc(100vw *12 / 375);
  }

  .dashboard_sidebar_menu .d_logo{
    padding-top: calc(100vw *18 / 375);
    padding-bottom: calc(100vw *17 / 375);
  }
  .dashboard_sidebar_menu .d_logo{
    padding-left: calc(100vw *15 / 375);
    padding-right: calc(100vw *15 / 375);
  }
  .dashboard_sidebar_menu .d_logo img{
  }



  .d_illustration {
    left: calc(100vw *-270 / 375);
    left: 0px;
    top: 0px;
    width: calc(100% + (100vw *270 / 375));
  
  }

  .d_illustration_signup{
    height: calc(100vw *160 / 375);
    object-position: center calc(100vw *-23 / 375);
  }
  .signup_form{
  }
  .signup_form_inner_content{
    width: calc(100vw *300 / 375);
  }
  .signup_form{
    padding: calc(100vw *30 / 375) var(--grid_padding);
  }
  .signup_logo {
    margin-bottom: calc(100vw *25 / 375);
    max-width: calc(100vw *150 / 375);
  }
  .signup_form_intro_content h1 {
    font-size: calc(100vw *20 / 375);
    margin-bottom: calc(100vw *10 / 375);
  }
  .signup_form_intro_content p {
    font-size: calc(100vw *16 / 375);
    margin-bottom: calc(100vw *10 / 375);
  }
  .signup_form_intro_content{
    margin-bottom: calc(100vw *30 / 375);
  }
  .signup_form_inner_content{
  }
  .signup_form_group input {
    font-size: calc(100vw *16 / 375);
    padding: calc(100vw *15 / 375) calc(100vw *20 / 375);
  }
  .signup_form_group{
    margin-bottom: calc(100vw *10 / 375);
  }
  .signup_checkboxes{
    padding-top: calc(100vw *10 / 375);
    margin-bottom: calc(100vw *20 / 375);
  }
  .z_checkbox{
    font-size: calc(100vw *16 / 375);
  }
  .z_checkbox>.box{
    width: calc(100vw *20 / 375);
    height: calc(100vw *20 / 375);
    margin-right: calc(100vw *15/375);
    border-radius: calc(100vw *5/375);
  }
  .z_checkbox.checked>.box:after{
    width: calc(100vw *14/375);
    height: calc(100vw *14/375);
    left: calc(50% - (100vw *7/375));
    top: calc(50% - (100vw *7/375));
    border-radius: calc(100vw *3/375);
  }
  .signup_recaptcha{
    margin-bottom: calc(100vw *20/375);
  }
  .signup_recaptcha>img{
  }
}