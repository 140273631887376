@media (min-width: 0px){

  body{
    
    --grid_padding: calc(100vw *30 / 1920);

    --container_width: calc(100vw *1410 / 1920 + 2*var(--grid_padding) );

    font-size: calc(100vw * 18 / 1920);

    
  }

  a,.btn{
    
  }

  *>*:last-child{
    margin-bottom: 0px !important;
  }

  ::-webkit-scrollbar {
    width: calc(100vw * 5 / 1920);
  }


  /*bootstrap start*/
  .container{
    padding: 0px var(--grid_padding);
  }
  .row>*{
    padding: 0px var(--grid_padding);
  }
  .row{
    margin: 0px calc(0px - var(--grid_padding));
  }

  .btn{
    border: solid calc(100vw *9 / 1920) #f2ecd1;
    -webkit-border-radius: calc(100vw *9 / 1920);
    border-radius: calc(100vw *9 / 1920);
    font-size: calc(100vw *22 / 1920);

    padding: calc(100vw *20 / 1920) calc(100vw *30 / 1920);
    
    text-shadow: calc(100vw *1 / 1920) calc(100vw *1 / 1920) calc(100vw *4 / 1920) #000000;
  

    -webkit-box-shadow: 0 calc(100vw *17 / 1920) calc(100vw *17 / 1920) calc(100vw *1 / 1920) rgba(0,0,0,0.3);
    box-shadow: 0 calc(100vw *17 / 1920) calc(100vw *17 / 1920) calc(100vw *1 / 1920) rgba(0,0,0,0.3);
  }
  .btn_header{
    padding-top: calc(100vw *15 / 1920);
    padding-bottom: calc(100vw *15 / 1920);
  }
  header .btn_header{
    min-width: calc(100vw * 290 / 1920);
  }
  .btn-warning{
    
  }
  .btn-warning:hover{
    
  }
  .btn-warning:active{

  }
  .btn-success{
    
  }
  .btn-success:hover{
    
  }

  .form-control{
    padding: calc(100vw *20 / 1920) calc(100vw *35 / 1920);
    font-size: calc(100vw *32 / 1920);
  }
  .form-control{
    -webkit-border-radius: calc(100vw * 5 / 1920);
    border-radius: calc(100vw * 5 / 1920);
  }
  /*bootstrap end*/




  header{
    
    left: 0px;
    top: 0px;
  
    height: calc(100vw *120 / 1920);
    border-width: calc(100vw *10 / 1920);
  }
  body.scrolled header{
    

    -webkit-box-shadow: 0 calc(100vw *20 / 1920) calc(100vw *28 / 1920) calc(100vw *1 / 1920) rgba(0,0,0,0.1);
    box-shadow: 0 calc(100vw *20 / 1920) calc(100vw *20 / 1920) calc(100vw *1 / 1920) rgba(0,0,0,0.1);
  }
  header .col-lg-12{
    
  }
  header ul{
    
    margin: 0px;
    padding: 0px;
    
  }
  .header_main_logo{
    
  }
  .header_main_logo_left{
    margin-right: calc(100vw * 40/1920);
  }
  .header_main_logo img{
    
    height: calc(100vw *71 / 1920);
  }
  .header_left_menu a{

    font-size: calc(100vw *20 / 1920);
    padding: calc(100vw *15 / 1920) 0px;
    
  }
  .header_left_menu a:after{

    height: calc(100vw *2 / 1920);
    left: 0px;
    bottom: 0px;
    
  }
  .header_left_menu a:not(:hover):after{
    
  }
  .header_left_menu>li{
    margin-right: calc(100vw *55 / 1920);
  }
  .header_left_menu>li:last-child{
    margin-right: 0px;
  }
  header .container{
    
  }
  .header_menu_user>li{
    
  }
  .header_menu_user>li:last-child{
    margin-right: 0px;
  }
  .btn_icon_header{
    
    font-size: calc(100vw *20 / 1920);
  }
  .btn_icon_header img{
    
    height: calc(100vw *19 / 1920);
    
    margin-right: calc(100vw *7 / 1920);
    vertical-align: calc(100vw *-3 / 1920);
  }
  header{
    
  }



  .big_header_with_banner{
    
    min-height: calc(100vw *900 / 1920);
    
    padding: calc(100vw *130 / 1920) 0px;
  }
  .big_header_with_banner .btn{
    min-width: calc(100vw * 400 / 1920);
  }
  .big_header_with_banner .container{
    
  }
  .big_header_banner{
    
    left: 0px;
    top: 0px;
    
  }
  .big_header_banner>img{
    
    left: 0px;
    bottom: 0px;
    
  }
  .big_header_with_banner .container{
    
    max-width: calc(100vw *1750 / 1920);
  }
  .big_header_with_banner .col-lg-12{
  }
  .big_header_with_banner .content{
    
    max-width: calc(100vw *665 / 1920);
    margin-top: calc(100vw *-150 / 1920);
  }
  .big_header_with_banner .content>img{
  
    margin-bottom: calc(100vw *60 / 1920);
  }
  .big_header_with_banner .content>h1{
    font-size: calc(100vw *125 / 1920);
    margin-bottom: calc(100vw *60 / 1920);
    text-shadow: 0px calc(100vw *5 / 1920) 0px #f06230;
  }
  .big_header_with_banner .content>h1>*{
  }
  .big_header_with_banner .content>h1>strong{
    margin-bottom: calc(100vw *5 / 1920) !important;
    text-shadow: 0px calc(100vw *5 / 1920) 0px #fab132;
    
  }
  .big_header_with_banner .content>h1>i{
    margin-bottom: calc(100vw * 10 / 1920);
    text-shadow: 0px calc(100vw *5 / 1920) 0px transparent;
  }


  .liberty_banner{
  
  }
  .liberty_banner .container{
    
  }
  .liberty_banner>img{
    
  }
  .liberty_banner .liberty_banner_content{
    

    -webkit-border-radius: calc(100vw *80 / 1920);
    border-radius: calc(100vw *80 / 1920);

    padding: calc(100vw *40 / 1920) calc(100vw *40 / 1920);
    padding-top: calc(100vw * 115 / 1920);
    padding-bottom: calc(100vw * 90 / 1920);

    
  }
  .liberty_banner .liberty_banner_content h1,
  .liberty_banner .liberty_banner_content h2,
  .liberty_banner .liberty_banner_content h3{
    font-size: calc(100vw *100 / 1920);
    font-size: calc(100vw *80 / 1920);
    letter-spacing: calc(100vw *-5 / 1920);
    text-shadow: 0px calc(100vw *5 / 1920) 0px #bdb785;
    margin-bottom: calc(100vw *25 / 1920);
    
  }
  .liberty_banner_content>div{
    margin-bottom: calc(100vw *35 / 1920);
  }
  .liberty_banner .liberty_banner_content .btn{
    min-width: calc(100vw *300 / 1920);
  }
  .liberty_banner .liberty_banner_content>*{
    
  }
  .liberty_banner{
    
    padding-top: calc(100vw *285 / 1920);
    padding-bottom: calc(100vw *365 / 1920);
  }
  .liberty_banner .right_big_circle{
    right: 0px;


    height: calc(100vw *1062 / 1920);
  }
  .liberty_banner .center_big_red_circle{
    bottom: calc(100vw *5 / 1920);
    right: calc(100vw *255 / 1920);
    height: calc(100vw *1064 / 1920);
    
  }
  .liberty_banner_content{
    
  }
  .liberty_banner_content .zraci{
  
    top: calc(50% + calc(100vw *100 / 1920));

    
  }
  .liberty_banner .left_circle{
    height: calc(100vw *337 / 1920);
    top: calc(50% - calc(100vw *400 / 1920));
  }
  .liberty_banner .left_circle_small{
    left: 0px;
    height: calc(100vw *160 / 1920);

    top: calc(50% + calc(100vw *80 / 1920));
  }
  .liberty_banner{
    
    background-size: auto calc(100vw *1200 / 1920);
  }




  .content_photo{
    padding: calc(100vw *195 / 1920) 0px;
  }
  .content_photo .content_top{
    margin-bottom: calc(100vw * 55 / 1920);
  }
  .content_photo .content_top h2,
  .content_photo .content_top h3{
    margin-bottom: calc(100vw * 25 / 1920);
    font-size: calc(100vw * 48 / 1920);
    text-shadow: 0px calc(100vw * 4 / 1920) 0px #ac4a39;
  }
  .content_photo .content_top p{
    font-size: calc(100vw * 22 / 1920);
    margin-bottom: calc(100vw * 25 / 1920);
  }
  .content_photo:before{
    right: 0px;
    top: 0px;
    
    border:solid calc(100vw *25 / 1920) #fcf5e9;
    
 
    -webkit-border-radius: calc(100vw *100 / 1920) 0 0 calc(100vw *100 / 1920);
    border-radius: calc(100vw *100 / 1920) 0 0 calc(100vw *100 / 1920);

    border-right: none;
    
  }
  .content_photo .container{
    
  }
  .content_photo .inner_content{
    
  }
  .content_photo .inner_content{
    
  }
  .content_photo .inner_content h5{
    font-size: calc(100vw *20 / 1920);
    margin-bottom: calc(100vw *35 / 1920);
    
    letter-spacing: calc(100vw *11 / 1920);
  }
  .content_photo .inner_content h2,
  .content_photo .inner_content h3{
    
    font-size: calc(100vw *72 / 1920);
    letter-spacing: calc(0px - 100vw * 4 / 1920);
    margin-bottom: calc(100vw *25 / 1920);
    text-shadow: 0px calc(100vw *5 / 1920) 0px #ac4a39;
  
  }
  .content_photo .inner_content p{
    font-size: calc(100vw *22 / 1920);
  }
  .content_photo .inner_content>*{

  }
  .content_photo .inner_content>img{
    
  }
  .content_photo .row{
    margin-bottom: calc(100vw *95 / 1920);
  }
  .content_photo.padding-bottom-for-x3-boxes{
    padding-bottom: calc(100vw * 405 / 1920);
  }
  .list_yellow_boxes{
    margin: 0px;
    padding: 0px;
    

  }
  .x3_yellow_boxes.margin-top-go-up{
    margin-top: calc(0px - 100vw * 355 / 1920);
  }
  .x3_yellow_boxes{
    margin-bottom: calc(100vw * 150 / 1920);
    padding-bottom: calc(100vw * 150 / 1920);
  }
  .list_yellow_boxes li{
    
    font-size: calc(100vw *22 / 1920);

    border: solid calc(100vw *20 / 1920) #fff9ef;

    padding: calc(100vw *30 / 1920) calc(100vw *20 / 1920);
    padding-top: calc(100vw *70 / 1920);
    padding-bottom: calc(100vw *50 / 1920);

    -webkit-border-radius: calc(100vw *80 / 1920);
    border-radius: calc(100vw *80 / 1920);
  }
  .list_yellow_boxes li>span{
    
  }
  .list_yellow_boxes h4{
    margin-bottom: calc(100vw *15 / 1920);
    font-size: calc(100vw *28 / 1920);
    text-shadow: 0px calc(100vw *5 / 1920) 0px #ac4a39;
  }
  .list_yellow_boxes li>span.logo{
    
    margin-bottom: calc(100vw *60 / 1920);
  }
  .list_yellow_boxes li>span.logo>img{
    
    width: calc(100vw *90 / 1920);
    height: calc(100vw *90 / 1920);

    
  }
  .list_yellow_boxes li>span.logo .logo_background,
  .list_yellow_boxes li>span.logo:before{
    
    width: calc(100vw *170 / 1920);
    height: calc(100vw *170 / 1920);
    


    left: calc(50% - calc(100vw *170 / 1920)/2);
    top: calc(50% - calc(100vw *170 / 1920)/2);

  
  }
  .list_yellow_boxes li>span.logo:before{
    
    
    width: calc(100vw *190 / 1920);
    height: calc(100vw *190 / 1920);
    left: calc(50% - calc(100vw *190 / 1920)/2);
    top: calc(50% - calc(100vw *190 / 1920)/2);

  

    filter: blur(calc(100vw *25 / 1920));
    -webkit-filter: blur(calc(100vw *25 / 1920));
  }
  .list_yellow_boxes li>span.logo:after{
    
    width: calc(100vw *200 / 1920);
    height: calc(100vw *200 / 1920);
    
    left: calc(50% - calc(100vw *200 / 1920)/2);
    top: calc(50% - calc(100vw *200 / 1920)/2);

    
  }
  .list_yellow_boxes{
    margin-bottom: calc(100vw *50 / 1920);
    margin-bottom: calc(100vw *110 / 1920);
  }
  .list_yellow_boxes li{
    -webkit-box-shadow: 0 calc(100vw *6 / 1920) 0 calc(100vw *1 / 1920) #d2cbc2;
    box-shadow: 0 calc(100vw *6 / 1920) 0 calc(100vw *1 / 1920) #d2cbc2;
  }

  .our_missing_buttons{
    
  }
  .our_missing_buttons>.btn{
    min-width: calc(100vw *300 / 1920);
  }

  .content_photo .row.minus_margin{
    margin-bottom: calc(100vw *-405 /1920) !important;
  }
  .content_photo{
    margin-bottom: calc(100vw *385 / 1920);
  }
  .content_photo:after{
    
    left: 0px;
    
    height: calc(100vw *385 / 1920);
    

    
  }



  .content_centered .container{
    
  }
  .content_centered .content{
    
  }
  .content_centered .content h5{
    font-size: calc(100vw *20 / 1920);
    letter-spacing: calc(100vw *11 / 1920);
    margin-bottom: calc(100vw *20 / 1920);
  }
  .content_centered .content h2,
  .content_centered .content h3{
    font-size: calc(100vw *80 / 1920);
    
    letter-spacing: calc(100vw *-3 / 1920);


    text-shadow: 0px calc(100vw * 5 / 1920) 0px #ac4a39;
    
  }
  .content_centered .content{
    margin-bottom: calc(100vw *65 / 1920);
  }
  .content_centered{
    padding-top: calc(100vw *115 / 1920);
    padding-bottom: calc(100vw *140 / 1920);
    
  }
  .content_centered:before{
    
    left: 0px;
    top: 0px;
    width: calc(100% - calc(100vw *70 / 1920));

    border: solid calc(100vw *25 / 1920) #fcf5e9;
    

    -webkit-border-radius: 0 calc(100vw *100 / 1920) calc(100vw *100 / 1920) 0;
    border-radius: 0 calc(100vw *100/1920) calc(100vw *100 / 1920) 0;

    border-left: none;


  }


  .list_box_posts li{
    
    padding: calc(100vw *30 / 1920) calc(100vw *25 / 1920);
    padding-top: calc(100vw *365 / 1920);
  
  }
  .list_box_posts li>img{
    
    left: 0px;
    top: 0px;
    
  }
  .list_box_posts li:hover img{
    
  }
  .list_box_posts li>*{
    
  }
  .list_box_posts li{
  
  }
  .list_box_posts li>span{
    
  }
  .list_box_posts li span.date{
    font-size: calc(100vw *14 / 1920);
    margin-bottom: calc(100vw *15 / 1920);
  }
  .list_box_posts li span h4{
    text-shadow: 0px calc(100vw *5 / 1920) 0px rgba(0,0,0,0);
    
  }
  .list_box_posts li:after{
    
    left: 0px;
    top: 0px;
    
  }




























  
  .newsletter{
    padding-top: calc(100vw *245 / 1920);
    padding-bottom: calc(100vw *350 / 1920);
  
    margin-bottom: calc(100vw *70 / 1920);
  }
  .newsletter .container{
    
  }
  .newsletter .banner_holder{
    left: 0px;
    top: 0px;
  }
  .newsletter .banner_holder img{
    left: 0px;
    bottom: 0px;
    height: calc(100vw *776 / 1920);
  }
  .newsletter .html_content h5{
    font-size: calc(100vw *20 / 1920);
    margin-bottom: calc(100vw *20 / 1920);
    letter-spacing: calc(100vw *11 / 1920);
  }
  .newsletter .html_content h2,
  .newsletter .html_content h3{

    font-size: calc(100vw *80 / 1920);
    letter-spacing: calc(0px - 100vw *2 / 1920);
  

    text-shadow: 0px calc(100vw *5 / 1920) 0px #ac4a39;
  }
  .newsletter .html_content p{
    font-size: calc(100vw *22 / 1920);
  }
  .newsletter .html_content{
    margin-bottom: calc(100vw *35 / 1920);
  }
  .newsletter .container{
    max-width: calc(calc(100vw *1560 / 1920) + 2*var(--grid_padding));
  }
  .newsletter .col-lg-12{
    
  }
  .newsletter_content{
    max-width: calc(100vw *770 / 1920);
  }
  .newsletter{
    background-size: auto calc(100vw *735 / 1920);

    margin-bottom: calc(100vw *70 / 1920);
  }


  footer{
    padding: calc(100vw *45 / 1920) 0px;
  }
  footer .col-lg-12{
    
  }
  .footer_main_link{
    
  }
  .footer_main_link img{
    
    width: calc(100vw *269 / 1920);
  }
  footer .container{
    
  }
  .footer_social_icons{
    padding: 0px;
    margin: 0px;
    
  }
  .footer_social_icons{
    
  }
  .footer_social_icons a{
    
  }
  .footer_social_icons a>img{
    
    width: calc(100vw *52 / 1920);
    height: calc(100vw *52 / 1920);
  }
  .footer_social_icons>li{
    margin-right: calc(100vw *10 / 1920);
  }
  .footer_social_icons>li:last-child{
    margin-right: 0px;
  }


  .big_header_with_content{
    
    min-height: calc(100vw *935 / 1920);

    padding-top: calc(100vw *190 / 1920);
    padding-bottom: calc(100vw *160 / 1920);

  
  }
  .big_header_with_content.add_margin{
    margin-bottom: calc(100vw *120 / 1920);
  }
  .big_header_with_content .container{
    
  }
  .big_header_with_content .col-lg-12{

  }
  .big_header_with_content .content{
    max-width: calc(100vw *800 / 1920);
  }
  .big_header_with_content .content h1,
  .big_header_with_content .content h2{
    font-size: calc(100vw *72 / 1920);

    text-shadow: calc(100vw *3 / 1920) calc(100vw *5 / 1920) 0px #784D2E;
    
    margin-bottom: calc(100vw *35 / 1920);
  }
  .big_header_with_content.is_for_post .content h1, .big_header_with_content.is_for_post .content h2{
    font-size: calc(100vw *50 / 1920);
  }
  .big_header_with_content .content p{
    font-size: calc(100vw *22 / 1920);
    
    margin-bottom: calc(100vw *25 / 1920);
  }
  .big_header_with_content .btn{
    min-width: calc(100vw *260 / 1920);
  }
  .big_header_with_content .rockets_holder{
    
    left: 0px;
    bottom: calc(100vw *-415 / 1920);
    
  }
  .big_header_with_content .rockets_holder .rockets{
    
    left: 0px;
    bottom: 0px;
    height: calc(100vw *1109 / 1920);
    
  }
  .big_header_with_content .rockets_holder:after{
    
    right: 0px;
    bottom: 0px;
    
    height: calc(100vw *1109 / 1920);
    background-size: auto calc(100vw *457 / 1920);

  }
  .big_header_with_content .the_planet{

    left: 0px;
    top: calc(100vw *175 / 1920);
    height: calc(100vw *310 / 1920);

    
  }
  .big_header_with_content{
    
  }
  .big_header_with_content .stars{
    
    left: 0px;
    top: 0px;
  }
  .big_header_with_content.is_for_post .col-lg-12>img{
  
    border: solid calc(100vw *20 / 1920) #fff9ef;
    border-radius: calc(100vw *80 / 1920);
    box-shadow: calc(100vw *6 / 1920) calc(100vw *6 / 1920) 0 calc(100vw *1 / 1920) #d2cbc2;
  }



  .content_tv_circle{
    
  }
  .content_tv_circle .circle_content{
  
    background-size: 100% calc(100% + (100vw *280 / 1920));
    
    max-width: calc(100vw *1230 / 1920);
  }
  .content_tv_circle .container{
    
  }
  .content_tv_circle .the_tv{
    width: calc(100vw *679 / 1920);
    margin-bottom: calc(100vw *35 / 1920);
    
  }
  .content_tv_circle .the_tv:before{
    
    left: calc(0px - 100vw *320 / 1920);
    width: calc(100% + 2 * (100vw *320 / 1920));
    top: calc(100vw *-130 / 1920);
    height: calc(100% + 2 * (100vw *130 / 1920));

    
  }
  .content_tv_circle .the_tv img{
    
  }
  .content_tv_circle .content{
    
    max-width: calc(100vw *755 / 1920);
    font-size: calc(100vw *22 / 1920);
    
  }
  .content_tv_circle .content p{
    margin-bottom: calc(100vw *30 / 1920);
  }
  .buttons_holder{
  
    padding-bottom: calc(100vw *45 / 1920);
  }
  .buttons_holder>*{
    margin: 0px calc(100vw *12 / 1920);
    min-width: calc(100vw *315 / 1920);
  }
  .content_tv_circle h2{
    
    font-size: calc(100vw *100 / 1920);
    margin-bottom: calc(100vw *25 / 1920);
    text-shadow: 0px calc(100vw *5 / 1920) 0px #78482b;
    letter-spacing: calc(100vw *-4 / 1920);
  }
  .content_tv_circle.title_smaller h2{
    font-size: calc(100vw *72 / 1920);
  }
  .content_tv_circle{
    padding-top: calc(100vw *140 / 1920);
    background-size: calc(100% - 2 * (100vw *50 / 1920)) auto;
    background-position: calc(100vw *50 / 1920) center;
    
  }
  .content_tv_circle .circle_content.just_tv{
    padding: calc(100vw *55 / 1920) 0px;
    max-width: calc(100vw *1040 / 1920);
  }

  .icons_content{
    margin: 0px;
    padding: 0px;
  }
  .icons_content li{
  
    margin-bottom: calc(100vw *100 / 1920);
  }
  .icons_content>li:nth-child(2n){
    
  }
  .icons_content li .image{
    
    width: calc(100vw *338 / 1920);
    height: calc(100vw *338 / 1920);
    
  }
  .icons_content li .image img{
    
    width: calc(100vw *138 / 1920);
    height: calc(100vw *138 / 1920);

    -webkit-border-radius: calc(100vw *69 / 1920);
    border-radius: calc(100vw *69 / 1920);
    

    -webkit-box-shadow: 0 calc(100vw *6 / 1920) 0 calc(100vw *1 / 1920) #d2cbc2;
    box-shadow: 0 calc(100vw *6 / 1920) 0 calc(100vw *1 / 1920) #d2cbc2;
  }
  .icons_content li .image::before{
    
    left: 0px;
    top: 0px;
    
  }
  .icons_content li .content{
    
    width: calc(100% - (100vw *338 / 1920) - (100vw *36 / 1920));
  }
  .icons_content li span{
  
  }
  .icons_content li h4{
    font-size: calc(100vw *36 / 1920);
    letter-spacing: calc(100vw *-2 / 1920);
    
    margin-bottom: calc(100vw *20 / 1920);

    text-shadow: 0px calc(100vw *5 / 1920) 0px #ac4a39;
  }
  .icons_content li p{
    margin-bottom: calc(100vw *25 / 1920);
    font-size: calc(100vw *22 / 1920);
    
  }
  .icons_content li .btn{
    min-width: calc(100vw * 320 / 1920);
  }


  .content_centered_2{
    padding-top: calc(100vw *130 / 1920);
    padding-bottom: calc(100vw *135 / 1920);
    
  }
  .content_centered_2 .container{
    
  }
  .content_centered_2 h1,
  .content_centered_2 h2,
  .content_centered_2 h3{
    font-size: calc(100vw *100 / 1920);
    margin-bottom: calc(100vw *45 / 1920);
    text-shadow: 0px calc(100vw *5 / 1920) 0px #78482b;
    
    letter-spacing: calc(100vw *-6 / 1920);
  }
  .content_centered_2 p{
    font-size: calc(100vw *22 / 1920);
    margin-bottom: calc(100vw *30 / 1920);
  }
  .content_centered_2 .container{
    
  }
  .content_centered_2{
    
  }
  .content_centered_2 .left_circle{
    
    height: calc(100vw *613 / 1920);
    left: 0px;
    top: calc(100vw *-20 / 1920);
  }
  .content_centered_2 .right_circle{
    
    height: calc(100vw *337 / 1920);
    right: 0px;
    top: calc(100vw *30 / 1920);
  }




  
  .logos_box{
    padding-top: calc(100vw * 85 / 1920);
  }
  .logos_box .container{
  }
  .logox_box_content{
    padding: calc(100vw * 55 / 1920) calc(100vw * 90 / 1920);
    padding-bottom: calc(100vw * 65 / 1920);

    -webkit-border-radius: calc(100vw * 50 / 1920);
    border-radius: calc(100vw * 50 / 1920);
  }
  .logos_box .content{
    margin-bottom: calc(100vw * 40 / 1920);
  }
  .logos_box .content h2,
  .logos_box .content h3{
    margin-bottom: calc(100vw * 25 / 1920);
    font-size: calc(100vw * 48 / 1920);
    letter-spacing: calc(0px - 100vw * 3 / 1920);
    text-shadow: calc(100vw * 3 / 1920) calc(100vw * 5 / 1920) 0px #fcddaf;
  }
  .logos_box .content p{
    font-size: calc(100vw * 22 / 1920);
    margin-bottom: calc(100vw * 25 / 1920);
  }
  .logox_box_content .the_logos{
  }
  .logox_box_content .the_logos img{
    height: calc(100vw * 87 / 1920);
    max-width: calc(100vw * 250 / 1920);

    margin: calc(100vw * 30 / 1920) calc(100vw * 10 / 1920) !important;
  }




  



  .content_boxes{
    margin-bottom: calc(100vw * 100 / 1920);
  }
  .content_box_item{
    border: solid calc(100vw * 10 / 1920) #fff9ef;

    -webkit-border-radius: calc(100vw * 70 / 1920);
    border-radius: calc(100vw * 70 / 1920);

    margin-bottom: calc(100vw * 100 / 1920);

    padding: calc(100vw * 90 / 1920) var(--grid_padding);
    min-height: calc(100vw * 505 / 1920);
  }
  .content_box_item>div{
    max-width: calc(100vw * 960 / 1920);
  }
  .content_box_item h2,
  .content_box_item h3{
    font-size: calc(100vw * 48 / 1920);
    margin-bottom: calc(100vw * 60 / 1920);
    letter-spacing: calc(0px - 100vw * 3/1920);
    text-shadow: 0px calc(100vw * 5/1920) 0px #ac4a39;
  }
  .content_box_item p{
    margin-bottom: calc(100vw * 30/1920);
    font-size: calc(100vw * 28/1920);
  }
































  
  .login_form{
    padding-top: calc(100vw *50 / 1920);
    padding-bottom: calc(100vw *215 / 1920);
  }
  .the_astronauts_on_the_sides{
    left: 0px;
    bottom: 0px;
  }
  .login_bottom_area{
    left: 0px;
    bottom: 0px;
  }
  .login_moon{

    width: calc(100vw *41 / 1920);


    left: calc(100vw *1230 / 1920);
    top: calc(100vw *25 / 1920);
  }
  .login_right_planets{

    right: 0px;
    top: calc(100vw *30 / 1920);

    width: calc(100vw *118 / 1920);
  }
  .login_the_stars{
    left: 0px;
    top: 0px;
  }
  .login_form_content{
  }
  .login_form_ufo{
    max-width: calc(100vw *332 / 1920);
    margin-bottom: calc(100vw *50 / 1920);
  }
  .login_form_inputs{
    max-width: calc(100vw *600 / 1920);
    padding: calc(100vw *60 / 1920);
    padding-bottom: calc(100vw *25 / 1920);

    -webkit-border-radius: calc(100vw *50 / 1920);
    border-radius: calc(100vw *50 / 1920);
  }
  .login_form_inputs .login_logo{
    margin-bottom: calc(100vw *40 / 1920);
    width: calc(100vw *248 / 1920);
  }
  .login_form_control_holder .form-control{
    -webkit-border-radius: calc(100vw *5 / 1920);
    border-radius: calc(100vw *5 / 1920);
    font-size: calc(100vw *18 / 1920);
    padding: calc(100vw *33 /1920) calc(100vw *30 / 1920);
  }
  .login_form_control_holder.have_icon .form-control{
    padding-right: calc((100vw *50 / 1920) + (100vw *30 / 1920) - (100vw * 20 / 1920));
  }
  .login_form_control_holder.have_icon{
    
  }
  .login_form_control_holder.have_icon>img{
    height: calc(100vw *24 / 1920);
    left: calc(100% - (100vw *50 / 1920));
    top: calc(50% - (100vw *24 / 1920)/2);
  }
  .login_form_control_holder{
    margin-bottom: calc(100vw * 25 / 1920);
  }
  .login_big_button_holder{
    padding-top: calc(100vw *10 / 1920);
    margin-bottom: calc(100vw *25 / 1920);
  }
  .login_big_button_holder>.btn{
    min-width: calc(100vw *290 / 1920);
    -webkit-box-shadow: calc(100vw -10 / 1920) calc(100vw *5 / 1920) calc(100vw *5 / 1920) calc(100vw *1 / 1920) rgba(0,0,0,0.4);
    box-shadow: calc(100vw *-10 / 1920) calc(100vw *5 / 1920) calc(100vw *5 / 1920) calc(100vw *1 / 1920) rgba(0,0,0,0.4);
  }
  .login_link{
  }
  .login_link:hover{
  }
  .login_forgot_link{
    margin-bottom: calc(100vw *75 / 1920);
  }
  .login_big_button_holder .error{
    margin-bottom: calc(100vw *5 / 1920);
  }







  .dashboard{
  
    position: relative;
  }
  .dashboard_sidebar_menu{
    left: 0px;
    top: 0px;
    width: calc(100vw *270 / 1920);
    
  }
  .dashboard_sidebar_menu .d_logo{
  
    width: calc(100vw *113 / 1920);
    padding-top: calc(100vw *25 / 1920);
    padding-bottom: calc(100vw *30 / 1920);
  }
  .dashboard_sidebar_menu .d_logo img{
    height: calc(100vw *27 / 1920);
  }
  
  .dashboard_sidebar_menu ul{
    padding: 0px;
    margin: 0px;

  }
  .dashboard_sidebar_menu a{
    font-size: calc(100vw *18 / 1920);
    padding-right: calc(100vw *15 / 1920);
    padding-top: calc(100vw *10 / 1920);
    padding-bottom: calc(100vw *10 / 1920);
  }
  .dashboard_sidebar_menu li a>img{
    width: calc(100vw *24 / 1920);
    height: calc(100vw *22 / 1920);
    margin-right: calc(100vw *15 / 1920);
  
    vertical-align: calc(100vw *-3 / 1920);
  }
  .dashboard_sidebar_menu ul{
  }
  .dashboard_sidebar_menu>ul>li>a{
    padding-left: calc(100vw *15 / 1920);
    padding-right: calc(100vw *15 / 1920);
  }
  .dashboard_sidebar_menu>ul>li>.submenu{
    width: calc(100% - (100vw *15 / 1920) - calc(100vw *15 / 1920));
  }
  .dashboard_sidebar_menu>ul>li>.submenu a{
    padding: calc(100vw *10 / 1920) 0px;
    padding-left: calc(100vw *37 / 1920);
    padding-left: calc(100vw *52 / 1920);
  }
  .dashboard_sidebar_menu>ul>li.opened{
    margin-bottom: calc(100vw *15 / 1920);
  }
  .dashboard_sidebar_menu>ul>li.opened>.submenu{
    padding-bottom: calc(100vw *10 / 1920);
    padding-bottom: calc(100vw *20 / 1920);
  }
  .dashboard_sidebar_menu li{
  }
  .dashboard_sidebar_menu>ul>li>a:hover,
  .dashboard_sidebar_menu>ul>li>a.active{

  }
  .dashboard_sidebar_menu>ul>li.opened>.submenu a:hover,
  .dashboard_sidebar_menu>ul>li.opened>.submenu a.active{
  }
  
  
  section.photo{
    margin: 5vw 0px;
  }
  section.photo img{

  
  }
  
  .dashboard_content_area{
    padding: calc(100vw *95 / 1920) calc(100vw *80 / 1920);
    padding-right: calc(100vw *120 / 1920);
    width: calc(100% - (100vw *270 / 1920));
    margin-left: calc(100vw *270 / 1920);
  
    /*
    border: solid calc(100vw *1 / 1920) red;*/
  }
  
  .dashboard_panel_count{
    -webkit-border-radius: calc(100vw *50 / 1920);
    border-radius: calc(100vw *50 / 1920);
  
    padding: calc(100vw *35 / 1920) calc(100vw *52 / 1920);

  }
  .dashboard_panel_count h4{
    font-size: calc(100vw *24 / 1920);
    margin: 0px;
  }
  .dashboard_panel_count .count{
    padding: calc(100vw *40 / 1920) 0px;
    font-size: calc(100vw *56 / 1920);
  }
  .dashboard_panel_count .count small{
    font-size: calc(100vw *18 / 1920);
  }
  
  .dashboard_counts_grid .col-lg-12{
    gap: calc(100vw *32 / 1920) calc(100vw *24 / 1920);
  }
  .dashboard_content_area .container{
    margin-bottom: calc(100vw *50 / 1920);
  }
  
  .dashboard_panels_x2_areas>div{
    margin-bottom: calc(100vw *20 / 1920);
  
    -webkit-border-radius: calc(100vw *15 / 1920);
    border-radius: calc(100vw *15 / 1920);
  }
  .dashboard_panels_x2_areas .area_1{
    min-height: calc(100vw *95 / 1920);
  }
  .dashboard_panels_x2_areas .area_2{
    min-height: calc(100vw *170 / 1920);
  }
  
  .dashboard_content_panel{
    
  
    -webkit-border-radius: calc(100vw *15 / 1920);
    border-radius: calc(100vw *15 / 1920);
  
    min-height: calc(100vw *575 / 1920);
  }
  
  
  .dashboard_sidebar_menu>ul>li.opened>.submenu a.active,
  .dashboard_sidebar_menu>ul>li>a.active{

  }
  .dashboard_sidebar_menu>ul>li.opened>.submenu a.active:before,
  .dashboard_sidebar_menu>ul>li>a.active::before{
  
    width: calc(100vw *6 / 1920);
    left: 0px;
    top: 0px;

  }











  
  .dashboard_content_area .container{

  }
  .dashboard_content_panel{
    padding: calc(100vw *40 / 1920) calc(100vw *50 / 1920);
    padding-bottom: calc(100vw *50 / 1920);
    min-height: 0px !important;
  }

  .dashboard_content_panel h1{
    font-size: calc(100vw *24 / 1920);
    margin-bottom: calc(100vw *20 / 1920);
  }
  .d_infoParagraph{
    font-size: calc(100vw *16 / 1920);
    padding: calc(100vw *5 / 1920) 0px;
    padding-left: calc(100vw *35 / 1920);
    background-size: calc(100vw *26 / 1920) calc(100vw *26 / 1920);

    margin-bottom: calc(100vw *25 / 1920);
  }
  .d_infoParagraph a{

  }
  .d_infoParagraph a:hover{
    
  }

  .d_copy_text{
    
  }
  .d_copy_text{
    font-size: calc(100vw *16 / 1920);
  }
  .d_copy_text span{
    margin-left: calc(100vw *18 / 1920);
    font-size: calc(100vw *16 / 1920);
    padding: calc(100vw *10 / 1920) calc(100vw *18 / 1920);

    padding-right: calc(100vw *50 / 1920);
    background-size: calc(100vw *16 / 1920) auto;
    background-position: calc(100% - (100vw *15 / 1920)) center;
    

    -webkit-border-radius: calc(100vw *7 / 1920);
    border-radius: calc(100vw *7 / 1920);


    border: solid calc(100vw *2 / 1920) #d7d7d7;
  }
  .d_copy_text input{
    padding: 0px;
    height: 0px;
  }
  .d_copy_text.copied span{
  }

  .d_hr{
    height: calc(100vw *3 / 1920);
    margin: calc(100vw *30 / 1920) 0px;
  }

  .d_centeredTexts{
    
  }
  .d_centeredTexts h5{
    font-size: calc(100vw *18 / 1920);
    margin-bottom: calc(100vw *15 / 1920);
  }
  .d_centeredTexts p{
    font-size: calc(100vw *18 / 1920);
    margin-bottom: calc(100vw *15 / 1920);
  }

  .dashboard_content_area{

  }
  .dashboard_content_area .container{
  
  }

  .d_illustration{
    left: calc(100vw *-270 / 1920);
    left: 0px;
    top: 0px;
    width: calc(100% + (100vw *270 / 1920));

    
  }

  .dashboard_sidebar_menu{
  }

  .dashboard_sidebar_menu>ul>li>.submenu{
  
  }

  .dashboard_sidebar_menu{
    position: fixed;
  }
  .dashboard_sidebar_menu>ul{
    height: calc(100vh - (100vw *135 / 1920) - (100vw *80 / 1920));
  }
  .dashboard_content_panel.light{

  }

  .dashboard_panel_count{
    
  }
  .dashboard_panel_count a{
    bottom: calc(100vw *25 / 1920);
    right: calc(100vw *25 / 1920);
    max-width: calc(100% - 2*(100vw *25 / 1920));
    font-size: calc(100vw *16 / 1920);
  }
  .dashboard_panel_count a:hover{
    
  }

  .d_icon_info{
    width: calc(100vw *25 / 1920);
    height: calc(100vw *25 / 1920);
  }
  .dashboard_panel_count .d_icon_info{
  
    top: calc(100vw *35 / 1920);
    right: calc(100vw *30 / 1920);
  }


  .wallet_widget{
    max-width: calc(100vw *590 / 1920);
  }
  .wallet_widget_header{
    margin-bottom: calc(100vw *20 / 1920);
    padding: calc(100vw *25 / 1920) calc(100vw *45 / 1920);
    

    -webkit-border-radius: calc(100vw *50/1920);
    border-radius: calc(100vw *50 / 1920);
  }
  .wallet_widget_header h1{
    font-size: calc(100vw *24 / 1920);
    margin-bottom: calc(100vw *25 / 1920);
  }
  .wallet_widget_header h1 img{
    

    margin-right: calc(100vw *15 / 1920);

    width: calc(100vw *44 / 1920);
    height: calc(100vw *44 / 1920);
  }
  .wallet_widget_header ul{
    padding: 0px;
    margin: 0px;
    max-width: calc(100vw *360 / 1920);
  }
  .wallet_widget_header ul li a{


    font-size: calc(100vw *18 / 1920);

    padding-bottom: calc(100vw *10  / 1920);

  
  }
  .wallet_widget_header ul li a:after{
    left: 0px;
    bottom: 0px;
    height: calc(100vw * 5 / 1920);
  }
  .wallet_widget_header ul li a.active{
    
  }
  .wallet_widget_header ul li a:hover:after,
  .wallet_widget_header ul li a.active:after{
  }

  .walled_widget_actions{
    
  }
  .walled_widget_actions .content{
    padding: calc(100vw *50 / 1920);
  }


  .btn_walled_send_receive{
    font-size: calc(100vw *20 / 1920);

    padding: calc(100vw *25 / 1920) calc(100vw *25 / 1920);
  }
  .btn_walled_send_receive:before{
    width: calc(100vw *13 / 1920);
    height: calc(100vw *27 / 1920);
    margin-right: calc(100vw *15 / 1920);
  }
  .btn_walled_send_receive.send{
    
  }
  .btn_walled_send_receive.receive{
    
  }
  .btn_walled_send_receive.receive:before{
  }

  .walled_widget_x2_buttons{
  }
  .walled_widget_x2_buttons>*{

  }


  .btn.btn-sm{
    font-size: calc(100vw *20 / 1920);
    padding: calc(100vw *14/1920) calc(100vw *35 / 1920);
  }

  .walled_widget_actions{
    -webkit-border-radius: calc(100vw *50 / 1920);
    border-radius: calc(100vw *50 / 1920);
  }

  .walled_widget_x2_buttons>*:first-child{
    -webkit-border-radius: calc(100vw *50 / 1920) 0 0 0;
    border-radius: calc(100vw *50 / 1920) 0 0 0;
  }
  .walled_widget_x2_buttons>*:last-child{
    -webkit-border-radius: 0 calc(100vw *50 / 1920) 0 0;
    border-radius: 0 calc(100vw *50 / 1920) 0 0;
  }
  .walled_widget_actions .content h3{
    margin-bottom: calc(100vw *30 / 1920);
    font-size: calc(100vw *20 / 1920);
  }

  .item_prices_holder .item_price{
    margin-bottom: calc(100vw *20 / 1920);
  }
  .item_prices_holder{
    margin-bottom: calc(100vw *45 / 1920);
  }
  .item_price{
    
    font-size: calc(100vw *18 / 1920);
  }
  .item_price h5{
    font-size: calc(100vw *16 / 1920);
    margin-bottom: calc(100vw *10 / 1920);
  }
  .item_price p{
    padding-left: calc(100vw *30 / 1920);
  }
  .item_price_info{
    
    margin-left: calc(100vw *5 / 1920);
    vertical-align: calc(100vw *-4 / 1920);
  }
  .item_price_info img{
    
    width: calc(100vw *17 / 1920);
  }


  .d_group{
    margin-bottom: calc(100vw *25 / 1920);
  }
  .d_group label{
    margin-bottom: calc(100vw *5 / 1920);
    font-size: calc(100vw *16 / 1920);
  }
  .d_group input{
    padding: calc(100vw *12 / 1920) calc(100vw * 14 / 1920);
    font-size: calc(100vw *16 / 1920);
    
    border: solid calc(100vw *2 / 1920) #d7d7d7 !important;
    
    -webkit-border-radius: calc(100vw *7 / 1920);
    border-radius: calc(100vw *7 / 1920);
  }
  .d_form_buttons>.btn{
    margin-right: calc(100vw *10 / 1920);
  }
  .d_form_buttons>.btn:last-child{
    margin-right: 0px;
    -webkit-box-shadow: calc(100vw *-8 / 1920) calc(100vw *3 / 1920) calc(100vw *8 / 1920) calc(100vw *1 / 1920) rgba(0,0,0,0.3);
    box-shadow: calc(100vw *-8 / 1920) calc(100vw *3 / 1920) calc(100vw *8 / 1920) calc(100vw *1 / 1920) rgba(0,0,0,0.3);
  }
  .d_number_suffix{
    
  }
  .d_number_suffix .right_controls{
    right: calc(100vw *10 / 1920);
    top: 0px;
  
  }
  .d_number_suffix .right_controls .label{
    margin-left: calc(100vw *15 / 1920);
    font-size: calc(100vw *16 / 1920);
    padding: calc(100vw *5 / 1920) calc(100vw *8 / 1920);

    -webkit-border-radius: calc(100vw *7 / 1920);
    border-radius: calc(100vw *7 / 1920);
  }
  .right_controls .arrows img{
    width: calc(100vw * 23 / 1920);
    height: calc(100vw * 23 / 1920);
  }
  .dashboard_sidebar_menu li a.account_link>img{
    width: calc(100vw *38 / 1920);
    height: calc(100vw *38 / 1920);
    margin-right: calc(100vw *5 / 1920);
  }
  .dashboard_sidebar_menu li a.account_link span{
  
  }
  .dashboard_sidebar_menu li a.account_link strong{
    
  }
  .dashboard_sidebar_menu li a.account_link{

  }


  .dashboard_sidebar_menu ul.logged_user{
    left: 0px;
    bottom: 0px;


    padding-top: calc(100vw *20 / 1920);
    padding-bottom: calc(100vw *20 / 1920);
  }
  .dashboard_sidebar_menu ul.logged_user li a{
    
  }
  .dashboard_sidebar_menu ul.logged_user li{
    margin-bottom: calc(100vw *15 / 1920);
  }














  
  .d_illustration_signup{
    width: calc(100vw *1433 / 1920) !important;
  }

  .signup_form{
    width: calc(100vw *975 / 1920);

    padding: calc(100vw *40 / 1920);

    right: 0px;
    top: 0px;
  }
  .signup_form_inner_content{
    width: calc(100vw *485 / 1920);
  }

  .z_checkbox{


    font-size: calc(100vw *18 / 1920);
  }
  .z_checkbox>*{
    
  }
  .z_checkbox input{
    
  }
  .z_checkbox>.box{
    width: calc(100vw *25 / 1920);
    height: calc(100vw *25 / 1920);
    margin-right: calc(100vw *20 / 1920);
    

    -webkit-border-radius: calc(100vw *5 / 1920);
    border-radius: calc(100vw *5 / 1920);

    
  }
  .z_checkbox.checked>.box:after{


    width: calc(100vw *20 / 1920);
    height: calc(100vw *20 / 1920);
    left: calc(50% - (100vw *10 / 1920));
    top: calc(50% - (100vw *10 / 1920));

    -webkit-border-radius: calc(100vw *5 / 1920);
    border-radius: calc(100vw *5 / 1920);


  }

  .signup_logo{

    margin-bottom: calc(100vw *35 / 1920);

    max-width: calc(100vw *201 / 1920);
  }
  .signup_logo img{
  }

  .signup_form_intro_content{
    margin-bottom: calc(100vw *30 / 1920);
  }
  .signup_form_intro_content h1{
    font-size: calc(100vw *24 / 1920);
    margin-bottom: calc(100vw *10 / 1920);
  }
  .signup_form_intro_content p{
    font-size: calc(100vw *18 / 1920);
    margin-bottom: calc(100vw *10/1920);
  }
  .signup_form_intro_content p a{
  }
  .signup_form_intro_content p a:hover{

  }
  .signup_form_group{
    margin-bottom: calc(100vw *20/1920);
  }
  .signup_form_group input{
    font-size: calc(100vw *18/1920);

    padding: calc(100vw *29/1920) calc(100vw *34/1920);
  }
  .signup_checkboxes{
    padding-top: calc(100vw *10/1920);
    margin-bottom: calc(100vw *25/1920);
  }
  .signup_recaptcha{
    margin-bottom: calc(100vw *30/1920);
  }
  .signup_recaptcha>img{
    
    width: calc(100vw *373/1920);
    height: calc(100vw *96/1920);

  }
  .signup_buttons_holder{

  }
  .signup_buttons_holder>.btn{
    min-width: calc(100vw *300/1920);
  }


  .wallet_balances{
    padding-top: calc(100vw *15/1920);
  }
  .wallet_balances h4{
    
    font-size: calc(100vw *18/1920);
    margin-bottom: calc(100vw *25/1920);
  }
  .wallet_balance_item{
    border: solid calc(100vw *2/1920) #d7d7d7;
    margin-bottom: calc(100vw *13/1920);

    padding: calc(100vw *12/1920) calc(100vw * 18/1920);



    -webkit-border-radius: calc(100vw *7/1920);
    border-radius: calc(100vw *7/1920);
  }
  .wallet_balance_item.warning{
    border-width: calc(100vw *3/1920);
  }
  .wallet_balance_item .warning_content{
    font-size: calc(100vw *18/1920);
    padding-left: calc(100vw *37/1920);
  
  }
  .wallet_balance_item .warning_content:before{
    width: calc(100vw *26/1920);
    height: calc(100vw *26/1920);
    left: 0px;
    top: 0px;
  }
  .wallet_balance_item .right_text{
    font-size: calc(100vw *18/1920);

    padding: calc(100vw *4/1920) 0px;
    padding-right: calc(100vw *45/1920);


    background-size: calc(100vw *33/1920) calc(100vw *33/1920);
  }
  .wallet_balance_item .right_text p{
    margin-bottom: calc(100vw *5/1920);
  }
  .wallet_balance_item .coin_content{
  }
  .wallet_balance_item .coin_content strong{
    font-size: calc(100vw *18/1920);
  }
  .wallet_balance_item .coin_content>img{
    display: block;
    width: calc(100vw *44/1920);
    height: calc(100vw *44/1920);
    margin-right: calc(100vw *20/1920);
  }

  section.photo>img{
  }
  .photo.photo_above_newsletter img{

  }
  .photo.photo_above_newsletter{

  }
  .photo.photo_above_newsletter:hover img{

  }

  
  .newsletter_content form button:not(.dropdown-toggle){
    min-width: calc(100vw * 400/1920);
  }














}
